import {IWrapperResponse} from '@/api/generic';
import {http, url} from '@/utils/http';

export class GltfModelApi {
    public async saveGltfModel(gltfBlob: File, playgroundId: number, force: boolean = false): Promise<IWrapperResponse<boolean>> {
        const formData = new FormData();
        formData.append('file', gltfBlob);
        formData.append('force', force.toString());
        const response = await http.post(
            url(`/api/v1/gltf-model/${playgroundId}`), formData);
        return response.data as IWrapperResponse<boolean>;
    }
}
