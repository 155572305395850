import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import {RootState} from '@/store';
import {IPartCategory} from '@/model/PartCategory';
import {IAgeCategory, IDifficultyCategory, IHeight, IMaterial} from '@/model/PartProperties';
import api from '@/api';
import {IPart} from '@/model/Part';
import {Pagination} from '@/utils/api-tools/pagination';

export interface PartState {
    partCategories: IPartCategory[];
    ageCategories: IAgeCategory[];
    difficultyCategories: IDifficultyCategory[];
    heights: IHeight[];
    materials: IMaterial[];
    parts: IPart[];
}

@Module
export default class PartModule extends VuexModule<PartState, RootState> {
    public partCategories: IPartCategory[] = [];
    public ageCategories: IAgeCategory[] = [];
    public difficultyCategories: IDifficultyCategory[] = [];
    public heights: IHeight[] = [];
    public materials: IMaterial[] = [];
    public parts: IPart[] = [];


    @Mutation
    public setPartCategories(value: IPartCategory[]) {
        this.partCategories.splice(0);
        this.partCategories.push(...value);
    }

    @Mutation
    public setAgeCategories(value: IAgeCategory[]) {
        this.ageCategories.splice(0);
        this.ageCategories.push(...value);
    }

    @Mutation
    public setDifficultyCategories(value: IDifficultyCategory[]) {
        this.difficultyCategories.splice(0);
        this.difficultyCategories.push(...value);
    }

    @Mutation
    public setHeights(value: IHeight[]) {
        this.heights.splice(0);
        this.heights.push(...value);
    }

    @Mutation
    public setMaterials(value: IMaterial[]) {
        this.materials.splice(0);
        this.materials.push(...value);
    }

    @Mutation
    public setParts(value: IPart[]) {
        this.parts.splice(0);
        this.parts.push(...value);
    }

    @MutationAction({
        mutate: ['partCategories', 'ageCategories', 'difficultyCategories', 'heights', 'materials', 'parts'],
    })
    public async fetchAllPart() {
        const partCategories = await api.part.getPartCategories();
        const ageCategories = await api.part.getAgeCategories();
        const difficultyCategories = await api.part.getDifficultyCategories();
        const heights = await api.part.getHeights();
        const materials = await api.part.getMaterials();
        const parts = await api.part.getParts(new Pagination(0, -1), []);

        return {
            partCategories: partCategories.data,
            ageCategories: ageCategories.data,
            difficultyCategories: difficultyCategories.data,
            heights: heights.data,
            materials: materials.data,
            parts: parts.data,
        };
    }
}


