import './plugins/component-hooks';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import settings from '@/utils/settings';
import {centsToEuro} from '@/utils/price';
import axios from 'axios';
import api from '@/api';
import './plugins/api';
import NotSupportedBrowser from '@/views/NotSupportedBrowser.vue';
import {http} from '@/utils/http';

/* IE is barely supports VueJS - show page that a different browser should be used */
const isInternetExplorer = /MSIE|Trident/.test(window.navigator.userAgent);
if (isInternetExplorer) {
    new Vue({render: (h) => h(NotSupportedBrowser)}).$mount('#app');
    throw new Error('Internet Explorer is not supported');
}

Vue.config.productionTip = false;
Vue.filter('euro', centsToEuro);

/* Prepare the HTTP Endpoint URL */
settings.HTTP_URL = process.env.VUE_APP_API || 'http://localhost:8080/';
settings.HTTP_URL = settings.HTTP_URL.replace(/\/+$/, '');
settings.ADMIN_URL = process.env.VUE_APP_ADMIN_URL || 'http://localhost:8082/';
settings.ADMIN_URL = settings.ADMIN_URL.replace(/\/+$/, '');
if (typeof process.env.VUE_APP_BUILDER_SEARCH_ENABLED !== 'undefined') {
    settings.BUILDER_SEARCH_ENABLED = process.env.VUE_APP_BUILDER_SEARCH_ENABLED;
}

/* set the router to check if need to be logged in before each navigation */
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !store.state.authentication.authenticated) {
        next('/login');
    } else {
        next();
    }
});


/* intercept responses to logout user */
http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401 && !error.request.responseURL.includes('/api/v1/me')) { // if the error is 401 and hasn't already been retried
            store.commit('setAuthenticated', false);
            router.push('login');
        }
        return error;
    });


/* create default Api object */
Object.defineProperty(Vue.prototype, '$api', {value: api}); // kinda hackish, but it works!


/* First we fetch our login status before starting the app, can be done differently but simple and effective */
let app: any;
http.get(settings.HTTP_URL + '/api/v1/me', {withCredentials: true})
    .then((response) => {
        const isAuthenticated = (response && response.data && response.data.success) || false;
        store.commit('setAuthenticated', isAuthenticated);
        if (isAuthenticated) {
            store.commit('setUser', response.data.data);
        }

        if (!app) {
            app = new Vue({
                router,
                store,
                api,
                render: (h) => h(App),
            });
            app.$mount('#app');
        }
    });
