import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IDampeningFloorCategory} from '@/model/DampeningFloorCategory';
import {http, url} from '@/utils/http';
import {IPagination} from '@/utils/api-tools/pagination';
import {ISearchCriterion} from '@/utils/api-tools/search-criteria';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IDampeningFloor} from '@/model/DampeningFloor';

export class DampingFloorApi {
    public async getDampingFloors(pagination: IPagination, search: ISearchCriterion[]):
        Promise<IWrapperResponse<IPaginationResponse<IDampeningFloor>>> {
        return new GenericApi<IDampeningFloor>('/api/v1/damping-floor').list(pagination, search);
    }

    public async getCategories() {
        const uri = new URL(url('api/v1/damping-floor/category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IDampeningFloorCategory[]>;
    }
}
