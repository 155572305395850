import axios from 'axios';
import settings from '@/utils/settings';

const http = axios.create({withCredentials: true});

function url(path: string) {
    return settings.HTTP_URL + '/' + path.replace(/^\/+/, '');
}

/**
 * Merges search parameters into specific parameter set.
 * @param target target to merge into
 * @param params the source URLSearchParams
 */
function mergeURLSearchParams(target: URLSearchParams, ...params: URLSearchParams[]): URLSearchParams {
    const result = target;
    params.forEach((p: URLSearchParams) => {
        p.forEach((value: string, key: string) => {
            result.append(key, value);
        });
    });
    return result;
}

export {
    http,
    url,
    mergeURLSearchParams,
};
