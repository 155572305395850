import {Instance2dData} from '@/model/2d/instance2dData';
import {Instance3dData} from '@/model/3d/instance3dData';
import {Playground} from '@/model/playground/playground';
import {ElementAttacher} from '@/model/playground/elementAttacher';
import {Pole} from '@/model/playground/pole';
import {PlaygroundPart} from '@/model/playground/playgroundPart';
import {IPartSpec} from '@/model/PartSpec';
import {UtilsPlayground} from '@/model/playground/utilsPlayground';
import {url} from '@/utils/http';
import {ModificationButton} from '@/model/2d/modificationButton';
import {Connector} from '@/model/2d/connector';

export interface ConnectorGroup {
    connector: number;
    group: string | null;
    offset: number;
    sizeGroup: string;
}

export class PlaygroundElement {
    private _connectedTo: ElementAttacher[] = [];
    private _openConnectors: number[] = [];
    private _allowedHeights: number[] = [];
    private _heightOffset = 0;
    private _poles: Pole[] = [];
    private _colors: any = {};
    private _id: number;

    constructor(private _model2d: Instance2dData, private _model3d: Instance3dData | null,
                private utilsPlayground: UtilsPlayground, private _playgroundPart: PlaygroundPart,
                private _partSpec: IPartSpec, private _uid: string, private playground?: Playground) {
        _model2d.playgroundElement = this;
        if (_model3d) {
            _model3d.playgroundElement = this;
        }
        if (_partSpec.id === undefined) {
            throw new Error('Part spec is missing id');
        }
        this._id = _partSpec.id;
        for (const connector of _model2d.connectors) {
            if (connector.connectable) {
                this._openConnectors.push(connector.id);
            }
        }
        for (const height of this._partSpec.heights) {
            this._allowedHeights.push(height.height);
        }
    }

    public getNextOpenConnectorButton(): ModificationButton | null {
        const buttons = this.model2d.buttons;
        for (const button of buttons) {
            const event: any = button.event;
            if (event.type === 'expand' && !button.hidden) {
                return button;
            }
        }
        return null;
    }

    public getConnectorButton(connectorId: number): ModificationButton | null {
        const buttons = this.model2d.buttons;
        for (const button of buttons) {
            const event: any = button.event;
            const connector: Connector = event.data.connector;
            if (event.type === 'expand' && !button.hidden && connector.id === connectorId) {
                return button;
            }
        }
        return null;
    }

    public collide(other: PlaygroundElement): boolean {
        return this._model2d.collide(other._model2d);
    }

    public addPole(pole: Pole) {
        this._poles.push(pole);
    }

    public async newInstance(playground: Playground, uid: string): Promise<PlaygroundElement> {
        return new PlaygroundElement(this._model2d.newInstance(), this._model3d ? this._model3d.newInstance() : null,
            this.utilsPlayground, this._playgroundPart, this._partSpec, uid, playground);
    }

    public attach(ownConnector: number, otherElement: PlaygroundElement, otherConnector: number): ElementAttacher {
        if (!otherElement.isRoof && !otherElement.isBench && !otherElement.isTable && !this.isBench
            && this._openConnectors.indexOf(ownConnector) === -1) {
            throw new Error('Connector not open for new connections');
        }
        const result = new ElementAttacher(ownConnector, otherElement, otherConnector);
        this._connectedTo.push(result);
        if (!otherElement.isRoof && !otherElement.isBench && !otherElement.isTable
            && this._openConnectors.indexOf(ownConnector) !== -1) {
            this._openConnectors.splice(this._openConnectors.indexOf(ownConnector), 1);
            if (this._model2d.connectors.length === 2) {
                this._openConnectors.splice(
                    this._openConnectors.indexOf((ownConnector + 1) % this._model2d.connectors.length), 1);
            }
        }
        this._model2d.updateButtons();
        return result;
    }

    public hasBenchConnector(): boolean {
        let result = false;
        for (const connector of this.model2d.connectors) {
            result = result || connector.bench;
        }

        return result;
    }

    public canConnectBench(): boolean {
        if (this.model2d.hasMultipleConnectables) {
            throw new Error('Method "canConnectBench" for playgroundElement (partId ' + this.playgroundPart.part.id
                + ') is not allowed: too many connectors. Use canConnectBenchAtConnector instead.');
        }
        return this.hasBenchConnector()
            && !this.getConnectedBenches().length
            && this.heightOffset >= 1200;
    }

    public canConnectBenchAtConnector(connectorNumber: number, sizeGroup: string | null): boolean {
        const checkConnectorTooLow = () => {
            return this.model2d.connectors.reduce((acc: boolean, curr) => {
                if (curr.id === connectorNumber) {
                    return acc && (curr.altitude + this.heightOffset) < 1200;
                }
                return acc;
            }, true);
        };

        if (this.heightOffset < 1200 && checkConnectorTooLow()) {
            return false;
        }

        let result = false;
        for (const connector of this.model2d.connectors) {
            result = result || (connector.id === connectorNumber && connector.bench
                && (sizeGroup === null || connector.sizeGroup === sizeGroup));
        }
        if (result) {
            result = !this.hasBenchAtConnector(connectorNumber, true);
        }
        return result;
    }

    public getConnectorGroups(): ConnectorGroup[] {
        return this._model2d.getConnectorGroups();
    }

    get poles(): Pole[] {
        return this._poles;
    }

    public getConnectedPoles(): Pole[] {
        return this._poles.filter((i) => i.isMainElement(this));
    }

    public connect(ownConnector: number, otherElement: PlaygroundElement, otherConnector: number) {
        this.attach(ownConnector, otherElement, otherConnector);
        otherElement.attach(otherConnector, this, ownConnector);
    }

    get allowedHeights(): number[] {
        return this._allowedHeights;
    }

    get maxAllowedHeight(): number {
        return Math.max(...this._allowedHeights);
    }

    get minAllowedHeight(): number {
        return Math.min(...this._allowedHeights);
    }

    get openConnectors(): number[] {
        return this._openConnectors;
    }

    get playgroundPart(): PlaygroundPart {
        return this._playgroundPart;
    }

    get uid(): string {
        return this._uid;
    }

    get colors(): any {
        return this._colors;
    }

    public isAllowedAltitude(value: number): boolean {
        return this._allowedHeights.indexOf(value) !== -1;
    }

    public isMaxHeight(): boolean {
        return this.heightOffset === this.maxAllowedHeight;
    }

    public isMinHeight(): boolean {
        return this.heightOffset === this.minAllowedHeight;
    }

    public changeAltitude(value: number) {
        this._heightOffset += value;
        if (this._model3d) {
            this._model3d.changeAltitude(value);
        }
        this.model2d.setAltitude(this._heightOffset);
        for (const pole of this._poles) {
            pole.updateAltitude(this);
        }
    }

    public async roofUpdateAltitude() {
        let maxAlt = 0;
        for (const pole of this._poles) {
            for (const element of pole.connectedElements) {
                if (element.playgroundElement.isFloor) {
                    if (element.playgroundElement.heightOffset > maxAlt) {
                        maxAlt = element.playgroundElement.heightOffset;
                    }
                }
            }
        }
        this.changeAltitude(maxAlt - this._heightOffset);
    }

    get partSpec(): IPartSpec {
        return this._partSpec;
    }

    get heightOffset(): number {
        return this._heightOffset;
    }

    set heightOffset(heightOffset: number) {
        const dt = heightOffset - this._heightOffset;
        this.changeAltitude(dt);
    }

    get model2d(): Instance2dData {
        return this._model2d;
    }

    public async getModel3d(): Promise<Instance3dData> {
        if (!this._model3d) {
            const model3dUrl = url('static/uploads/' + this._partSpec.gltfModel);
            this._model3d = await this.utilsPlayground.utils3d.loadModel(model3dUrl, this.playgroundPart);
            this._model3d.playgroundElement = this;
            this._model3d.changeAltitude(this._heightOffset);
        }
        return this._model3d;
    }

    get id(): number {
        return this._id;
    }

    get isFloor(): boolean {
        return this.playgroundPart.isFloor;
    }

    get isEntrance(): boolean {
        return this.playgroundPart.isEntrance;
    }

    get isSlide(): boolean {
        return this.playgroundPart.isSlide;
    }

    get isBench(): boolean {
        return this.playgroundPart.isBench;
    }

    get isRoof(): boolean {
        return this.playgroundPart.isRoof;
    }

    get isTable(): boolean {
        return this.playgroundPart.isTable;
    }

    get connectedTo(): ElementAttacher[] {
        return this._connectedTo;
    }

    public export() {
        const connected: any[] = [];
        for (const connector of this._connectedTo) {
            connected.push(connector.export());
        }
        return {
            height: this._heightOffset, uid: this._uid, type: this._id, connected,
            colors: this._colors,
        };
    }

    public getColor(selectedColor: string): number {
        return this._colors[selectedColor];
    }

    public hasColors(): boolean {
        return Object.keys(this._colors).length > 0;
    }

    public async updateColor(selectedColor: string, number: number) {
        this._colors[selectedColor] = number;
        this._model2d.updateColor(selectedColor, number);
        (await this.getModel3d()).updateColor(selectedColor, number);
    }

    public deleteConnections() {
        for (const connector of this._connectedTo) {
            connector.connectToElement.detach(this, connector.fromConnector, connector.toConnector);
        }
        for (const pole of this._poles) {
            pole.deleteConnector(this);
        }
    }

    public connectorAltitude(connector: number) {
        return this._heightOffset + this._model2d.getConnectorOffset(connector);
    }

    public connectorMountingAltitude(connector: number) {
        return this._heightOffset + this._model2d.getConnectorMountingOffset(connector);
    }
    public getConnectorMountingOffset(connector: number) {
        return this._model2d.getConnectorMountingOffset(connector);
    }

    public getConnectable(): boolean[] {
        return this._model2d.getConnectable();
    }

    public get2dItem(): Instance2dData {
        return this.model2d.newInstance();
    }

    public getSelectButtons(enabled: number[], disabled: number[]) {
        return this._model2d.getSelectButtons(enabled, disabled);
    }

    public getConnectedBenches(): PlaygroundElement[] {
        const result: PlaygroundElement[] = [];
        for (const connectedToItem of this._connectedTo) {
            const connectedElement = connectedToItem.connectToElement;
            if (connectedElement.isBench && !result.includes(connectedElement)) {
                result.push(connectedElement);
            }
            const benchAtOtherElement = connectedElement.getBenchAtConnector(connectedToItem.toConnector, false);
            if (benchAtOtherElement && !result.includes(benchAtOtherElement)) {
                result.push(benchAtOtherElement);
            }
        }
        return result;
    }

    public getBenchAtConnector(
        connector: number,
        includeBenchAtConnectedElement = false,
    ): PlaygroundElement | null {
        for (const connectedToItem of this._connectedTo) {
            if (connectedToItem.fromConnector !== connector) {
                continue;
            }
            const connectedToElement = connectedToItem.connectToElement;
            if (connectedToElement.isBench) {
                return connectedToElement;
            }

            if (includeBenchAtConnectedElement) {
                // Check if the connectedElement has a bench at this connector. If that's the case, then return this
                // bench.
                const benchAttacher: ElementAttacher | null = connectedToElement.connectedTo.find((elmAttacher) => {
                    return elmAttacher.fromConnector === connectedToItem.toConnector
                        && elmAttacher.connectToElement !== this
                        && elmAttacher.connectToElement.isBench;
                }) || null;
                if (benchAttacher) {
                    return benchAttacher.connectToElement;
                }
            }
        }
        return null;
    }

    public hasBenchAtConnector(connector: number, includeBenchAtConnectedElement = false): boolean {
        return this.getBenchAtConnector(connector, includeBenchAtConnectedElement) !== null;
    }

    public getConnectedRoof(): PlaygroundElement | null {
        for (const connectedToItem of this._connectedTo) {
            const connectedElement = connectedToItem.connectToElement;
            if (connectedElement.isRoof) {
                return connectedElement;
            }
        }
        return null;
    }

    public getConnectedTable(): PlaygroundElement | null {
        for (const connectedToItem of this._connectedTo) {
            const connectedElement = connectedToItem.connectToElement;
            if (connectedElement.isTable) {
                return connectedElement;
            }
        }
        return null;
    }

    private detach(from: PlaygroundElement, fromConnector: number, connector: number) {
        if (!from.isRoof && !from.isBench && !from.isTable) {
            this._openConnectors.push(connector);
        }
        for (let i = 0; i < this._connectedTo.length; ++i) {
            const connectorAttacher = this._connectedTo[i];
            if (connectorAttacher.connectToElement === from && connectorAttacher.toConnector === fromConnector
                && connectorAttacher.fromConnector === connector) {
                this._connectedTo.splice(i, 1);
                --i;
            }
        }
        this._model2d.updateButtons();
    }
}
