import {AuthenticationApi} from '@/api/auth';
import {PartApi} from '@/api/part';
import {DampingFloorApi} from '@/api/damping-floor';
import {PlaygroundApi} from '@/api/playground';
import {GltfModelApi} from '@/api/gltf-model';
import {ColorThemeApi} from '@/api/color-theme';
import {GenericApi} from '@/api/generic';
import {IColor} from '@/model/Color';
import {ColorApi} from '@/api/color';

/**
 * Grouping class for all APIs
 */
export class Api {
    public readonly auth = new AuthenticationApi();
    public readonly part = new PartApi();
    public readonly dampingFloor = new DampingFloorApi();
    public readonly playground = new PlaygroundApi();
    public readonly color = new ColorApi();
    public readonly gltfModel = new GltfModelApi();
    public readonly colorTheme = new ColorThemeApi();
}

const api = new Api();

export default api;
