import {PerspectiveCamera, Vector3, WebGLRenderer} from 'three';
import {View2d} from '@/model/2d/view2d';
import {OrbitControls2} from '@/model/3d/OrbitControls';

export class View3d {
    private _camera?: PerspectiveCamera;
    private _magnification = 1;
    private _renderer!: WebGLRenderer;
    private _controls!: OrbitControls2;
    private _startDistance!: number;
    private readonly minAngle = 10;
    private readonly maxAngle = 90;
    private _rotation = 0;
    private modifier = 1;
    private _angle = 10;
    private distance!: number;
    private _side = 0;

    set controls(value: OrbitControls2) {
        this._controls = value;
    }

    set camera(value: PerspectiveCamera) {
        this._camera = value;
        this.distance = this._camera.position.distanceTo(this._controls.target);
        this._startDistance = this.distance;
        this.update();
    }

    get startDistance(): number {
        return this._startDistance;
    }

    public zoom(zoom: number) {
        if (zoom < 0) {
            this._controls.dollyOut(-zoom);
        } else {
            this._controls.dollyIn(zoom);
        }
        this._controls.update();
    }

    set magnification(value: number) {
        this._magnification = value;
        this.distance = this._startDistance * this._magnification;
        this.update();
    }

    set target(value: Vector3) {
        this._controls.target.x = value.x;
        this._controls.target.y = value.y;
        this._controls.target.z = value.z;
        this.update();
    }

    get target(): Vector3 {
        return this._controls.target;
    }

    set renderer(renderer: WebGLRenderer) {
        this._renderer = renderer;
    }

    public saveState() {
        this._controls.saveState();
    }

    public reset() {
        this._controls.reset();
    }

    public resize(width: number, height: number) {
        if (this._renderer) {
            this._renderer.setSize(width, height);
            if (this._camera) {
                this._camera.aspect = width / height;
                this._camera.updateProjectionMatrix();
            }
        }
    }

    public absolutePosition(side: number, angle: number) {
        this._side = (360 + (side % 360)) % 360;
        this._angle = angle;
        this.update();
    }

    public position(side: number, angle: number) {
        this._side = (360 + (side % 360)) % 360;
        this._angle = angle;
        if (this._angle > this.maxAngle) {
            this._angle = this.maxAngle;
        } else if (this._angle < this.minAngle) {
            this._angle = this.minAngle;
        }
        this.update();
    }

    public move(dx: number, dy: number) {
        this.position(this._side + dx * this.modifier, this._angle + -dy * this.modifier);
    }

    public rotate(value: number) {
        this._rotation += value;
        this.position(this._side, this._angle);
    }

    public update() {
        if (this._camera) {
            const side = (((this._side + this._rotation) % 360) / 180) * Math.PI;
            const angle = (this._angle / 180) * Math.PI;
            const distance = Math.cos(angle) * this.distance;
            const y = Math.sin(angle) * this.distance;
            const x = Math.sin(side) * distance;
            const z = Math.cos(side) * distance;
            this._camera.position.x = x + this._controls.target.x;
            this._camera.position.y = y + this._controls.target.y;
            this._camera.position.z = z + this._controls.target.z;

            this._camera.lookAt(this._controls.target);
        }
    }
}
