import {PlaygroundElement} from '@/model/playground/playgroundElement';
import {Vector2} from 'three';
import {PoleConnector} from '@/model/playground/poleConnector';
import {Playground} from '@/model/playground/playground';

export class Pole {
    private _connectedElements: PoleConnector[] = [];
    private _mainElement: PoleConnector;
    private _location: Vector2;

    constructor(playgroundElement: PlaygroundElement, connector: number, private playground: Playground) {
        this._location = playgroundElement.model2d.getAbsoluteConnectorPosition(connector);
        this._mainElement = new PoleConnector(playgroundElement, connector);
        this._connect(this._mainElement);
    }

    public connect(playgroundElement: PlaygroundElement, connector: number) {
        this._connect(new PoleConnector(playgroundElement, connector));
    }

    public deleteConnector(playgroundElement: PlaygroundElement) {
        if (this._connectedElements.length === 1) {
            this.playground.removePole(this);
        } else {
            let newMain: PoleConnector | null = null;
            for (let i = 0; i < this._connectedElements.length; ++i) {
                if (this._connectedElements[i].playgroundElement === playgroundElement) {
                    this._connectedElements.splice(i, 1);
                    --i;
                } else if (newMain === null || newMain.altitude < this._connectedElements[i].altitude) {
                    newMain = this._connectedElements[i];
                }
            }
            if (newMain !== null) {
                this._mainElement = newMain;
                this._mainElement.update();
                this.playground.updatePole(this);
            }
        }
    }

    get mainElement(): PoleConnector {
        return this._mainElement;
    }

    get altitude(): number {
        return this._mainElement.altitude;
    }

    get location(): Vector2 {
        return this._location;
    }

    public isMainElement(element: PlaygroundElement) {
        return this._mainElement.playgroundElement === element;
    }

    public async get3dLocation(): Promise<Vector2> {
        return (await this._mainElement.playgroundElement.getModel3d())
            .getConnectorAbsolutePosition(this.mainElement.connector);
    }

    get connectedElements(): PoleConnector[] {
        return this._connectedElements;
    }

    public updateAltitude(element: PlaygroundElement) {
        let change = false;
        for (const connector of this._connectedElements) {
            if (connector.playgroundElement === element) {
                connector.update();
                change = this._mainElement === connector;
            }
        }
        for (const connector of this._connectedElements) {
            if (this._mainElement.altitude < connector.altitude) {
                this._mainElement = connector;
                change = true;
            }
        }
        if (change) {
            this.playground.updatePole(this);
        }
    }

    private _connect(connector: PoleConnector) {
        this._connectedElements.push(connector);
        if (this._mainElement.altitude < connector.altitude) {
            this._mainElement = connector;
            this.playground.updatePole(this);
        }
    }
}
