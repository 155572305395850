import {Playground} from '@/model/playground/playground';
import {IPlayground, PlaygroundStatus} from '@/model/Playground';
import {PlaygroundApi} from '@/api/playground';
import {hasRole} from '@/store-modules/auth';
import {State} from 'vuex-class';
import {IUser} from '@/model/User';
import {Vue} from 'vue-property-decorator';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';

export class PlaygroundSaveHandler extends Vue {

    private playgroundApi: PlaygroundApi;
    @State((state: any) => state.authentication.user)
    private currentUser!: IUser;

    constructor(playgroundApi: PlaygroundApi) {
        super();
        this.playgroundApi = playgroundApi;
    }

    public async save(oldPlaygroundInfo: IPlayground, playground: Playground) {
        let playgroundInfo: IPlayground = playground.export();
        if (!playgroundInfo) {
            return;
        }
        playgroundInfo.name = oldPlaygroundInfo.name;
        playgroundInfo.description = oldPlaygroundInfo.description;
        playgroundInfo.state = PlaygroundStatus.CONCEPT; // concept
        playgroundInfo.id = oldPlaygroundInfo.id ? oldPlaygroundInfo.id : undefined;

        if (playgroundInfo.template) {
            playgroundInfo.template = false;
            playgroundInfo.id = undefined;
        }
        if (playgroundInfo.floor != null && (playgroundInfo.floor.type == null || playgroundInfo.floor.type === 0)) {
            delete playgroundInfo.floor;
        }
        if (!playgroundInfo.id) {
            const playgroundApiData = await this.playgroundApi.createPlayground(playgroundInfo);
            const playgroundData: any = playgroundApiData.data;
            if (playgroundData) {
                playgroundInfo = playgroundData;
            }
        } else {
            const playgroundApiData = await this.playgroundApi.savePlayground(playgroundInfo);
            const playgroundData: any = playgroundApiData?.data;
            if (playgroundData) {
                playgroundInfo = playgroundData;
            }
        }
        try {
            const result = await playground.screenshot(1920 / 2, 1080 / 2, 20, 30);
            await this.playgroundApi.saveIcon(playgroundInfo.id || 0, result);
        } catch (e) {
            // it's okay to fail
        }

        this.saveGltf(playground, playgroundInfo);

        return playgroundInfo;
    }

    public async saveGltf(playground: Playground, playgroundInfo: IPlayground, forceSave = false) {
        const exporter = new GLTFExporter();
        const exportScene = playground.world3d.exportGltf;
        const playgroundName = playgroundInfo?.name || 'unknown';
        const sanitizedPlaygroundName = playgroundName.replaceAll(/[^a-zA-Z0-9_-]/g, '-');
        const date = new Date().toISOString().split('T')[0];
        const extension = '.gltf'
        const fileName = date + '-3d-model-speeltoestel-' + sanitizedPlaygroundName + extension;
        exporter.parse(
            exportScene,
            async (gltf) => {
                const gltfBlob = new Blob([JSON.stringify(gltf)], {type: 'application/json'});
                const file = new File([gltfBlob], fileName);
                await this.$api.gltfModel.saveGltfModel(file, playgroundInfo.id!, forceSave);
            },
            (error) => {
                console.log(error)
            },
        );
    }

    private get isAdmin(): boolean {
        return hasRole(this.currentUser, 'admin');
    }
}
