import {World3d} from '@/model/3d/wold3d';
import {World2d} from '@/model/2d/world2d';
import {UtilsPlayground} from '@/model/playground/utilsPlayground';
import {PlaygroundElement} from '@/model/playground/playgroundElement';
import {LocationMap} from '@/model/util/treeMap';
import {Pole} from '@/model/playground/pole';
import {Instance2dData} from '@/model/2d/instance2dData';
import {PartCategoryType} from '@/model/PartCategory';
import {Vector2} from 'three';
import {MinimalBoundingBox} from '@/model/2d/minimalBoundingBox';
import {FallSurface} from '@/model/2d/fallSurface';
import {Step} from '@/model/playground/step';
import {ModificationButton} from '@/model/2d/modificationButton';
import {ElementAttacher} from '@/model/playground/elementAttacher';
import {Mutex} from '@/utils/mutex';
import {IDampeningFloor} from '@/model/DampeningFloor';
import {IPart} from '@/model/Part';
import store from '@/store';
import {IColorTheme} from '@/model/ColorTheme';
import {Connector} from '@/model/2d/connector';
import {IHeight} from '@/model/PartProperties';

export interface FitResult {
    connectors: number[];
    rotatable: boolean;
    width: number | null;
    height: number | null;
    modelHtml: string | null;
    buttons: ModificationButton[] | null;
}

export interface ChangeAltitudeResult {
    element: PlaygroundElement;
    boundElements: PlaygroundElement[];
    potentialRoofElements: PlaygroundElement[];
}

export interface RotationDefinition {
    connectorTo: number;
    elementConnectedTo: string;
    connectorConnectedTo: number;
}

export class Playground {

    private static readonly POLE_DISTANCE: number = 1000;
    private static readonly MAX_ALT_DIFF: number = 600;

    public colorTheme: IColorTheme | null = null;
    public prevColorTheme: IColorTheme | null = null;

    private elements: Map<string, PlaygroundElement> = new Map<string, PlaygroundElement>();
    private _dampeningFloor: IDampeningFloor | null = null;
    private _poles: LocationMap<Pole> = new LocationMap<Pole>();
    private currentHistoryItem = -1;
    private runMutex!: Readonly<Mutex>;
    private readonly _world3d: World3d;
    private readonly _world2d: World2d;
    private main?: PlaygroundElement;
    private loading = false;
    private _testSvg = '';
    private history: any[] = [];
    private steps: Step[] = [];
    private id: number | null = null;
    private _dampingFloorRotation?: number;
    private _perpendicular = false;
    private _deltaRotation = 0;

    constructor(private utilsPlayground: UtilsPlayground, private id3dDiv: string) {
        this._world2d = new World2d(this.utilsPlayground.utils2d);
        this._world3d = new World3d(this.utilsPlayground.utils3d, id3dDiv);
    }

    get playgroundId(): number | null {
        return this.id;
    }

    get testSvg(): string {
        return this._testSvg;
    }

    get world2d(): World2d {
        return this._world2d;
    }

    get world3d(): World3d {
        return this._world3d;
    }

    get dampeningFloor(): IDampeningFloor | null {
        return this._dampeningFloor;
    }

    get playgroundElements(): Map<string, PlaygroundElement> {
        return this.elements;
    }

    get mainElement(): PlaygroundElement | undefined {
        return this.main;
    }

    get dampingFloorRotation(): number | undefined {
        return this._dampingFloorRotation;
    }

    get deltaRotation(): number {
        return this._deltaRotation;
    }

    get perpendicular(): boolean {
        return this._perpendicular;
    }

    get numberOfFloors(): number {
        let numberOfFloors = 0;
        for (const element of this.elements.values()) {
            numberOfFloors += element.isFloor ? 1 : 0;
        }
        return numberOfFloors;
    }

    get numberOfSlides(): number {
        let numberOfSlides = 0;
        for (const element of this.elements.values()) {
            numberOfSlides += element.isSlide ? 1 : 0;
        }
        return numberOfSlides;
    }

    get numberOfEntrances(): number {
        let numberOfEntrances = 0;
        for (const element of this.elements.values()) {
            numberOfEntrances += element.isEntrance ? 1 : 0;
        }
        return numberOfEntrances;
    }

    public hasEntrance(): boolean {
        let result = false;
        for (const element of this.elements.values()) {
            result = result || (element.isFloor && element.heightOffset <= 300)
                || element.isEntrance;
        }
        return result;
    }

    get price() {
        let totalPrice = 0;
        for (const element of this.elements.values()) {
            totalPrice += element.partSpec.price;
        }
        if (this.dampeningFloor && this.dampeningFloor.tileWidth && this.dampeningFloor.tileHeight) {
            const fallSurfaceVectors = this.getFallSurfaceBoundingBoxVectors();
            const width = fallSurfaceVectors[0].distanceTo(fallSurfaceVectors[1]);
            const height = fallSurfaceVectors[0].distanceTo(fallSurfaceVectors[3]);
            const tilesPerWidth = Math.ceil(width / this.dampeningFloor.tileWidth);
            const tilesPerHeight = Math.ceil(height / this.dampeningFloor.tileHeight);
            totalPrice +=  this.dampeningFloor.price * tilesPerWidth * tilesPerHeight;
        }
        return totalPrice;
    }

    public async undo() {
        await this.runMutex.acquire();
        try {
            if (this.canUndo()) {
                --this.currentHistoryItem;
                if (this.currentHistoryItem >= 0) {
                    await this._load(this.history[this.currentHistoryItem]);
                } else {
                    await this._cleanup();
                }
            }
        } finally {
            this.runMutex.release();
        }
    }

    public removeLastHistoryItem() {
        if (this.history.length > 1) {
            this.history.pop();
        }
        this.currentHistoryItem = this.history.length - 1;
    }

    public rotateDampingFloorPosition(deltaRotation: number) {
        if (this._dampingFloorRotation == null) {
            const boundingBox = new MinimalBoundingBox();
            this._world2d.addBoundingBoxFallItems(boundingBox);
            boundingBox.calculate();
            this._dampingFloorRotation = boundingBox.rotation * 180 / Math.PI  + deltaRotation;
        } else {
            this._dampingFloorRotation = this._dampingFloorRotation + deltaRotation;
        }
        if (this._dampingFloorRotation === 0) {
            this.resetPlacementAngle();
        } else {
            this.recalcBoundingBoxSvg();
        }
        this._deltaRotation += deltaRotation;
    }

    public cropRotation() {
        this.world2d.view.resetRotation();
        this._dampingFloorRotation = undefined;
        this._deltaRotation = 0;
        this._perpendicular = false;
        this.recalcBoundingBoxSvg();
    }

    public resetPlacementAngle() {
        const rotation =  this.main ? this.main.model2d.rotation : 0;
        this.world2d.view.rotate(90 - rotation - this.world2d.view.rotation);

        this._deltaRotation = 0;
        this._dampingFloorRotation = rotation;
        this._perpendicular = true;

        const boundingBox = new MinimalBoundingBox();
        this._world2d.addBoundingBoxFallItems(boundingBox);
        const fallSurface = new FallSurface(boundingBox, this._dampeningFloor,  this.world2d.view.rotation);
        fallSurface.calculate(rotation, false);
        this._testSvg = fallSurface.svg;
    }

    public async fillWithFence(fence: IPart) {
        await this.runMutex.acquire();
        try {
            for (const key of this.elements.keys()) {
                const element = this.elements.get(key) as PlaygroundElement;
                const openConnectors = element.openConnectors.slice();
                const height = element.heightOffset;

                if (height > 300) {
                    for (const connector of openConnectors) {
                        const fitResult = await this._fitTest(fence.id!, element.uid, connector);
                        if (fitResult.connectors.length > 0) {
                            await this._addItem(fence.id!, height, false, undefined, fitResult.connectors[0],
                                element.uid, connector, false);
                        }
                    }
                }
            }
            this.addHistoryItem();
        } finally {
            this.runMutex.release();
        }
    }

    public async hasOpenRequiredConnectors(): Promise<boolean> {
        await this.runMutex.acquire();
        try {
            for (const element of this.elements.values()) {
                const height = element.heightOffset;
                const openConnectors = element.openConnectors;
                // Floors are allowed to have open connectors if their height is 60cm or below
                if (element.isFloor && height <= 600) {
                    continue;
                }
                if (openConnectors.length > 0) {
                    return true;
                }
            }
            return false;
        } finally {
            this.runMutex.release();
        }
    }

    public async redo() {
        await this.runMutex.acquire();
        try {
            if (this.canRedo()) {
                await this._load(this.history[++this.currentHistoryItem]);
            }
        } finally {
            this.runMutex.release();
        }
    }

    public canRedo(): boolean {
        return this.currentHistoryItem < this.history.length - 1;
    }

    public canUndo(): boolean {
        return this.currentHistoryItem >= 1;
    }

    public hasMain(): boolean {
        return !!this.main;
    }

    public async cleanup() {
        await this.runMutex.acquire();
        try {
            this.fullCleanup();
        } finally {
            this.runMutex.release();
        }
    }

    public async load(input: any) {
        if (input.perpendicular) {
            this.resetPlacementAngle();
            if (input.deltaRotation) {
                this.world2d.view.rotate(input.deltaRotation * -1);
                this.rotateDampingFloorPosition(input.deltaRotation);
            }
        }

        await this.runMutex.acquire();
        try {
            this.fullCleanup();
            await this._load(input);
            this.addHistoryItem();
        } finally {
            this.runMutex.release();
        }

        if (!input.perpendicular && input.deltaRotation) {
            this.world2d.view.rotate(input.deltaRotation * -1);
            this.rotateDampingFloorPosition(input.deltaRotation);
        }
    }

    public async fitTest(type: number, connectedToInstance: string, connector: number): Promise<FitResult> {
        await this.runMutex.acquire();
        try {
           return this._fitTest(type, connectedToInstance, connector);
        } finally {
            this.runMutex.release();
        }
    }

    public async canRotate(uid: string): Promise<RotationDefinition | null> {
        await this.runMutex.acquire();
        try {
            const done: Array<string | null> = [];
            const alts: number[] = [];
            let result: RotationDefinition | null = null;
            const rotator = this.elements.get(uid);
            if (rotator === undefined) {
                throw new Error('Unknown uid');
            }
            const connectorGroups = rotator.getConnectorGroups();
            const nextGroup = (id: number) => {
                let min = id;
                let next = -1;
                for (const group of connectorGroups) {
                    if (min > group.connector) {
                        min = group.connector;
                    }
                    if ((next === -1 || next > group.connector) && (id < group.connector)) {
                        next = group.connector;
                    }
                }
                return next === -1 ? min : next;
            };
            for (const group of connectorGroups) {
                if (done.indexOf(group.group) === -1) {
                    done.push(group.group);
                }
                if (alts.indexOf(group.offset) === -1) {
                    alts.push(group.offset);
                }
            }
            if (done.length > 1 && alts.length === 1 && rotator.connectedTo[0]) {
                const mainConnector = rotator.connectedTo[0].fromConnector;
                const otherElement = rotator.connectedTo[0].connectToElement.uid;
                const otherConnector = rotator.connectedTo[0].toConnector;
                let nextConnector = nextGroup(mainConnector);
                while (result === null && nextConnector !== mainConnector) {
                    if (rotator.isRoof || rotator.isTable || await this.testFit(
                        rotator.partSpec.id!,
                        rotator.heightOffset,
                        true,
                        nextConnector,
                        otherElement,
                        otherConnector,
                        [rotator.uid],
                        rotator.connectedTo.map((element: ElementAttacher) => element.connectToElement.uid),
                    )) {
                        result = {
                            connectorTo: nextConnector,
                            elementConnectedTo: otherElement,
                            connectorConnectedTo: otherConnector,
                        };
                    }
                    nextConnector = nextGroup(nextConnector);
                }
            }
            return result;
        } finally {
            this.runMutex.release();
        }
    }

    public async rotate(uid: string, rotatorType: RotationDefinition) {
        const element = await this.directDeleteElement(uid);
        const colors = element.colors;
        const rotatedElement = await this._addItem(element.partSpec.id!, element.heightOffset, true, element.uid,
            rotatorType.connectorTo, rotatorType.elementConnectedTo, rotatorType.connectorConnectedTo);
        if (rotatedElement instanceof PlaygroundElement) {
            for (const colorKey in colors) {
                if (Object.prototype.hasOwnProperty.call(colors, colorKey)) {
                    await rotatedElement.updateColor(colorKey, colors[colorKey]);
                }
            }
            this.addHistoryItem();
        }
    }

    public async addItem(type: number, heightOffset: number, loadedItem: boolean, uid?: string, connector?: number,
                         connectedToInstance?: string,
                         connectedToConnector?: number): Promise<PlaygroundElement | Error> {
        await this.runMutex.acquire();
        try {
            return await this._addItem(type, heightOffset, loadedItem, uid, connector, connectedToInstance,
                connectedToConnector);
        } finally {
            this.runMutex.release();
        }
    }

    public canChangeAltitude(uid: string, value: number): boolean {
        try {
            this.testChangeAltitude(uid, value);
            return true;
        } catch (e) {
            return false;
        }
    }

    public async changeAltitude(uid: string, value: number) {
        await this.runMutex.acquire();
        try {
            const changeAltitudeResult = this.testChangeAltitude(uid, value);
            const element = changeAltitudeResult.element;
            const potentialRoofElements = changeAltitudeResult.potentialRoofElements;
            element.changeAltitude(value);
            for (const step of this.steps) {
                if (step.matches(element)) {
                    await step.update();
                }
            }
            for (const bound of changeAltitudeResult.boundElements) {
                bound.changeAltitude(value);
            }
            const roofs = [];
            for (const pole of element.poles) {
                for (const connectedElement of pole.connectedElements) {
                    potentialRoofElements.push(connectedElement.playgroundElement);
                }
            }
            for (const potentialRoofElement of potentialRoofElements) {
                for (const other of potentialRoofElement.connectedTo) {
                    const type = other.connectToElement.playgroundPart.part.category.type;
                    if (type === PartCategoryType.ROOF) {
                        if (roofs.indexOf(other.connectToElement) === -1) {
                            roofs.push(other.connectToElement);
                        }
                    }
                }
            }
            for (const roof of roofs) {
                await roof.roofUpdateAltitude();
            }
            if (value !== 0) {
                this.addHistoryItem();
            }
        } finally {
            this.runMutex.release();
        }
    }

    public testChangeAltitude(uid: string, value: number): ChangeAltitudeResult {
        const element = this.elements.get(uid);
        if (element === undefined) {
            throw new Error('Unknown uid');
        }
        if (!element.isFloor) {
            throw new Error('Can only increment floors');
        }
        const newAltitude = element.heightOffset + value;
        if (element.allowedHeights.indexOf(newAltitude) === -1) {
            throw new Error('Cannot go to this altitude');
        }
        let minAltOthers: number | null = null;
        let maxAltOthers: number | null = null;
        const changeAlso = [];
        const potentialRoofElements = [element];
        for (const other of element.connectedTo) {
            const type = other.connectToElement.playgroundPart.part.category.type;
            if (type === PartCategoryType.FLOOR) {
                const altitude = other.connectToElement.connectorAltitude(other.toConnector)
                    - element.model2d.getConnectorOffset(other.fromConnector);
                if (minAltOthers === null || minAltOthers > altitude) {
                    minAltOthers = altitude;
                }
                if (maxAltOthers === null || maxAltOthers < altitude) {
                    maxAltOthers = altitude;
                }
            } else if (type === PartCategoryType.BOUND) {
                if (other.connectToElement.allowedHeights.indexOf(other.connectToElement.heightOffset + value) === -1
                    || (other.connectToElement.getConnectedBenches().length > 0 && newAltitude < 1200)) {
                    throw new Error('Bound cannot go to this altitude');
                }
                changeAlso.push(other.connectToElement);
            } else if ([PartCategoryType.NORMAL, PartCategoryType.ENTRANCE, PartCategoryType.SLIDE]
                .includes(type)) {
                // The changeAltitude method can be used for 'refreshing' the altitude for roof position.
                // Only throw exception for other elements when height is actually changed.
                if (element.heightOffset !== newAltitude) {
                    throw new Error('Cannot change altitude of connected part');
                }
            }
            if (type === PartCategoryType.BENCH || other.connectToElement.hasBenchAtConnector(other.toConnector)) {
                if (newAltitude < 1200) {
                    throw new Error('Cannot go lower than 1200 when connected to bench');
                }
            }
        }
        if ((minAltOthers !== null && Math.abs(newAltitude - minAltOthers) > Playground.MAX_ALT_DIFF)
        || (maxAltOthers !== null && Math.abs(newAltitude - maxAltOthers) > Playground.MAX_ALT_DIFF)) {
            throw new Error('Max altitude diff reached');
        }
        return {
            element,
            potentialRoofElements,
            boundElements: changeAlso,
        };
    }

    public canDelete(uid: string): boolean {
        const toRemove = this.elements.get(uid);
        if (!toRemove || this.playgroundElements.size === 1) {
            return false;
        }
        const processedElements = this.retrieveElementChainedFloors(toRemove);
        let processedFloors = toRemove.isFloor ? 1 : 0;
        processedFloors += processedElements.length;
        return this.numberOfFloors === processedFloors;
    }

    public retrieveElementChainedFloors(toRemove: PlaygroundElement): PlaygroundElement[] {
        const todo = [];
        if (this.hasMain()) {
            // Only need to check for floors and elements that are connected to other elements as well
            const cascadingConnectors = toRemove.connectedTo.filter((attacher) => {
                return attacher.connectToElement.connectedTo.length > 1 || attacher.connectToElement.isFloor;
            });
            if (cascadingConnectors.length > 0) {
                todo.push(cascadingConnectors[0].connectToElement);
            }
            let offset = 0;
            while (offset < todo.length) {
                for (const element of todo[offset].connectedTo) {
                    if (element.connectToElement !== toRemove && todo.indexOf(element.connectToElement) === -1) {
                        todo.push(element.connectToElement);
                    }
                }
                ++offset;
            }
        }
        return todo.filter((playgroundElement) => playgroundElement.isFloor);
    }

    public async deleteElement(uid: string, forceDelete = false) {
        await this.runMutex.acquire();
        try {
            if (!forceDelete && !this.canDelete(uid)) {
                throw new Error('Cannot delete item');
            }
            await this.directDeleteElement(uid);
            await this.deleteOrphanBenches();
            await this.deleteTablesWithoutBench();
            this.addHistoryItem();
        } finally {
            this.runMutex.release();
        }
    }

    public async deleteOrphanBenches() {
        for (const element of this.playgroundElements.values()) {
            if (!element.isBench) {
                continue;
            }
            let isOrphan = true;
            for (const connectedToItem of element.connectedTo) {
                if (connectedToItem.connectToElement) {
                    isOrphan = false;
                    break;
                }
            }
            if (isOrphan) {
                await this.directDeleteElement(element.uid);
            }
        }
    }

    public async deleteTablesWithoutBench() {
        for (const element of this.playgroundElements.values()) {
            if (!element.isTable) {
                continue;
            }
            const floorElement = element.connectedTo[0].connectToElement;
            let floorHasOtherBench = false;
            for (const connectedToItem of floorElement.connectedTo) {
                if (connectedToItem.connectToElement.hasBenchAtConnector(connectedToItem.toConnector, true)) {
                    floorHasOtherBench = true;
                }
            }
            if (!floorHasOtherBench) {
                await this.directDeleteElement(element.uid);
            }
        }
    }

    public export(): any {
        const result: any = {elements: {}};
        this.elements.forEach((element) => result.elements[element.uid] = element.export());
        if (this.main) {
            result.mainElement = this.main.uid;
            const boundingBox = new MinimalBoundingBox();
            this._world2d.addBoundingBoxFallItems(boundingBox);
            const fallSurface = new FallSurface(boundingBox, this._dampeningFloor);
            fallSurface.calculate();
            result.floor = {type: this.dampeningFloor ? this.dampeningFloor.id : 0, dampingFloorArea: fallSurface.area};
            result.poles = this._poles.export('size', (pole) => pole.altitude + 600);
            result.steps = this.exportSteps();
            const heights: number[] = this._world2d.calculatedHeights();
            result.maxHeight = heights[1]; // max safetyHeight
            result.numberOfEntrances = this.numberOfEntrances;
            result.numberOfSlides = this.numberOfSlides;
            result.id = this.id;
            result.colorTheme = this.colorTheme;
            result.perpendicular = this._perpendicular;
            result.deltaRotation = this._deltaRotation;
        }
        return result;
    }

    public async init() {
        await this.runMutex.acquire();
        try {
            await this._world3d.init(this._world2d.view, this.utilsPlayground.poleGltfUrl);
        } finally {
            this.runMutex.release();
        }
    }

    public async updateColor(uid: string, selectedColor: string, color: number) {
        await this.runMutex.acquire();
        try {
            const element = this.elements.get(uid);
            if (element === undefined) {
                throw new Error('Unknown uid');
            }
            await element.updateColor(selectedColor, color);
            this.addHistoryItem();
        } finally {
            this.runMutex.release();
        }
    }

    public async updateByColorTheme(updateColorTheme: IColorTheme) {
        this.prevColorTheme = this.colorTheme;
        this.colorTheme = updateColorTheme;

        const colorValue1 = parseInt(updateColorTheme.color1!.value.replace('#', ''), 16);
        const colorValue2 = parseInt(updateColorTheme.color2!.value.replace('#', ''), 16);
        const colorValue3 = parseInt(updateColorTheme.color3!.value.replace('#', ''), 16);

        for await (const [key, value] of this.playgroundElements.entries()) {
            await this.updateColor(key, 'color1', colorValue1);
            await this.updateColor(key, 'color2', colorValue2);
            await this.updateColor(key, 'color3', colorValue3);
            await this.updateColor(key, 'color4', colorValue1);
            await this.updateColor(key, 'color5', colorValue2);
            await this.updateColor(key, 'color6', colorValue3);
        }
    }

    public async getConnectors(newType: number, height: number): Promise<boolean[]> {
        return await this.utilsPlayground.getConnectors(newType, height);
    }

    public removePole(pole: Pole) {
        this.world3d.removePole(pole);
        this._poles.remove(pole);
    }

    public updatePole(pole: Pole) {
        this.world3d.updatePole(pole);
    }

    public recalcSafetyAnnotation() {
        this._world2d.recalcSafetyAnnotation();
    }

    public recalcBoundingBoxSvg() {
        const boundingBox = new MinimalBoundingBox();
        this._world2d.addBoundingBoxFallItems(boundingBox);
        const fallSurface = new FallSurface(boundingBox, this._dampeningFloor, this.world2d.view.rotation);
        fallSurface.calculate(this._dampingFloorRotation, !this._perpendicular);
        this._testSvg = fallSurface.svg;
    }

    public getFallSurfaceBoundingBoxVectors(): Vector2[] {
        const boundingBox = new MinimalBoundingBox();
        this._world2d.addBoundingBoxFallItems(boundingBox);
        boundingBox.calculate(this._dampingFloorRotation, !this._perpendicular);
        return boundingBox.minimalBoundingBox;
    }

    public lookFromDirection(side: number, angle: number) {
        const boundingBox = new MinimalBoundingBox();
        this._world2d.addBoundingBoxMeasurementItems(boundingBox);
        this._world3d.lookFromDirection(boundingBox.getDirectionalBoundingBox((side / 180) * Math.PI),
            this._world2d.calculatedHeights()[2], side, angle);
    }

    public async screenshot(width: number, height: number, side: number, angle: number): Promise<string> {
        const boundingBox = new MinimalBoundingBox();
        this._world2d.addBoundingBoxMeasurementItems(boundingBox);
        const heights: number[] = this._world2d.calculatedHeights();
        return await this._world3d.takeScreenshot(width, height, side, angle,
            boundingBox.getDirectionalBoundingBox((side / 180) * Math.PI), heights[0], heights[1], heights[2]);
    }

    public getId(): number | null {
        return this.id;
    }

    public testInit() {
        this.runMutex = Object.seal(new Mutex());
    }

    public getMaxHeight(): number {
        let maxHeight = 0;
        for (const element of this.elements.values()) {
            if (maxHeight < element.heightOffset) {
                maxHeight = element.heightOffset;
            }
        }
        return maxHeight;
    }

    public getPartnameMaxFallHeight(): string {
        return this.world2d.partNameHighestSafetyHeight();
    }

    public getMaxFallHeight(): number {
        return this.world2d.calculatedHeights()[1];
    }

    public setDampeningFloor(dampeningFloor: IDampeningFloor | null) {
        this._dampeningFloor = dampeningFloor;
        this.recalcBoundingBoxSvg();
    }

    public getColorThemeColor(colorName: string): number {
        const colorNo = parseInt(colorName.replace('color', ''), 10);
        let colorValue = '#FFFFFF';
        switch (colorNo % 3) {
            case 0: {
                colorValue = this.colorTheme!.color3!.value;
                break;
            }
            case 1: {
                colorValue = this.colorTheme!.color1!.value;
                break;
            }
            case 2: {
                colorValue = this.colorTheme!.color2!.value;
                break;
            }

        }

        return parseInt(colorValue.replace('#', ''), 16);
    }

    get poles(): LocationMap<Pole> {
        return this._poles;
    }

    private async loadItem(input: any, uid: string, fromConnector?: number, connectedToInstance?: string,
                           connectedToConnector?: number, defaultRotation?: number) {
        const element = await this._addItem(input.elements[uid].type, input.elements[uid].height, true, uid,
            fromConnector, connectedToInstance, connectedToConnector, undefined, defaultRotation || 0);
        if (element instanceof PlaygroundElement) {
            for (const i in input.elements[uid].colors) {
                if (Object.prototype.hasOwnProperty.call(input.elements[uid].colors, i)) {
                    const color = input.elements[uid].colors[i];
                    await element.updateColor(i, color);
                }
            }
            for (const connector of input.elements[uid].connected) {
                if (!this.elements.get(connector.toElement)) {
                    await this.loadItem(input, connector.toElement, connector.toConnector, uid,
                        connector.fromConnector, defaultRotation || 0);
                }
            }
             } else {
            //     console.error('Error while loading item: ', element);
        }
        if (Object.prototype.hasOwnProperty.call(input, 'id')) {
            this.id = input.id;
        }
    }

    private async _fitTest(type: number, connectedToInstance: string, connector: number): Promise<FitResult> {
        const done: Array<string | null> = [];
        const fit: Array<string | null> = [];
        const connectors: number[] = [];
        const disabled: number[] = [];
        const connectToElement = this.elements.get(connectedToInstance);
        if (connectToElement === undefined) {
            throw new Error('Unknown uid');
        }
        for (const group of await this.utilsPlayground.getConnectorGroups(type)) {
            if (done.indexOf(group.group) === -1) {
                if (await this.testFit(type, connectToElement.connectorAltitude(connector), false, group.connector,
                    connectedToInstance, connector, null)) {
                    connectors.push(group.connector);
                    fit.push(group.group);
                }
                done.push(group.group);
            } else if (fit.indexOf(group.group) !== -1) {
                connectors.push(group.connector);
            }
        }
        const rotatable = done.length > 1;
        let width = null;
        let height = null;
        let modelHtml = null;
        let buttons = null;
        if (rotatable) {
            const selectButtons = await this.utilsPlayground.getSelectButtons(type, connectors, disabled);
            width = selectButtons.width;
            height = selectButtons.height;
            modelHtml = selectButtons.modelHtml;
            buttons = selectButtons.buttons;
        }
        return {rotatable, connectors, width, height, modelHtml, buttons};
    }

    private async _addItem(type: number, heightOffset: number, loadedItem: boolean, uid?: string, connector?: number,
                           connectedToInstance?: string,
                           connectedToConnector?: number, addHistory?: boolean,
                           defaultRotation = 0): Promise<PlaygroundElement | Error> {
        let newElement: PlaygroundElement;
        try {
            if (!(connector !== undefined && connectedToInstance !== undefined
                && connectedToConnector !== undefined) && this.main) {
                throw new Error('Must connect to other element');
            }
            if (connector !== undefined && connectedToInstance !== undefined && connectedToConnector !== undefined) {
                if (!await this.testFit(type, heightOffset, loadedItem, connector, connectedToInstance,
                    connectedToConnector, null)) {
                    throw new Error('Cannot place this');
                }
            }
            newElement = await (loadedItem ? this.utilsPlayground.getInstance(type, heightOffset, this,
                uid || this.utilsPlayground.generateUid())
                : this.utilsPlayground.getItem(type, heightOffset, this, uid || this.utilsPlayground.generateUid()));
            if (connector !== undefined && connectedToInstance !== undefined && connectedToConnector !== undefined) {
                const connectToElement = this.elements.get(connectedToInstance);
                if (connectToElement === undefined) {
                    throw new Error('Unknown uid');
                }
                connectToElement.connect(connectedToConnector, newElement, connector);
                this._world2d.addItem(newElement.model2d, connector, connectToElement.model2d,
                    connectedToConnector, defaultRotation);
                newElement.heightOffset = heightOffset;
                if (!loadedItem) {
                    newElement.heightOffset += -newElement.model2d.getConnectorAltitude(connector)
                        + newElement.model2d.zero;
                }
                this._world3d.addItem(await newElement.getModel3d(), newElement.heightOffset - newElement.model2d.zero,
                    connector, await connectToElement.getModel3d(), connectedToConnector);
                if (newElement.isFloor && connectToElement.isFloor) {
                    const step = new Step(newElement, connector, connectToElement, connectedToConnector, this,
                        this.utilsPlayground);
                    await step.update();
                    this.steps.push(step);
                }
            } else {
                connector = undefined;
                this.main = newElement;
                newElement.heightOffset = heightOffset;
                this._world2d.addItem(newElement.model2d, undefined, undefined, undefined, defaultRotation);
                this._world3d.addItem(await newElement.getModel3d(), newElement.heightOffset - newElement.model2d.zero);
            }
            await this.handlePoles(newElement, connector);
            this.elements.set(newElement.uid, newElement);
            for (const colorName of newElement.model2d.colors) {
                if (this.colorTheme) {
                    await newElement.updateColor(colorName, this.getColorThemeColor(colorName));
                } else {
                    await newElement.updateColor(colorName, newElement.playgroundPart.getDefaultColor(colorName));
                }
            }
            if (newElement.isRoof) {
                await newElement.roofUpdateAltitude();
            }
            if (!loadedItem && addHistory !== false) {
                this.addHistoryItem();
            }
        } catch (e) {
            this._load(this.history[this.currentHistoryItem]);
            newElement = e as any;
        }
        return newElement;
    }

    private async _load(input: any, rotation?: number) {
        if (!this.loading) {
            this._cleanup();
            if ('mainElement' in input) {
                this.loading = true;
                this.colorTheme = input.colorTheme;
                await this.loadItem(input, input.mainElement, undefined, undefined, undefined, rotation);
                await this.updateRoofs();
                const floor = input.floor;
                if (floor) {
                    const dampeningFloor: IDampeningFloor = await store.getters.findDampingFloor(floor.type);
                    if (dampeningFloor) {
                        this.setDampeningFloor(dampeningFloor);
                    }
                    if (floor.rotation != null && floor.rotation !== 0) {
                        this._dampingFloorRotation = floor.rotation;
                        this.world2d.view.rotate(90 - floor.rotation - this.world2d.view.rotation);
                        this.recalcBoundingBoxSvg();
                    }
                }
                for (const step of this.steps) {
                    await step.update();
                }
                this.loading = false;
            }
        }
    }

    private async updateRoofs() {
        for (const potentialRoofElement of this.elements.values()) {
            if (potentialRoofElement.isRoof) {
                await potentialRoofElement.roofUpdateAltitude();
            }
        }
    }

    private _cleanup() {
        this.main = undefined;
        this.steps.splice(0, this.steps.length);
        this._poles.clear();
        this.elements.clear();
        this._world2d.cleanup();
        this._world3d.cleanup();
    }

    private addHistoryItem() {
        if (this.currentHistoryItem < this.history.length - 1) {
            this.history.splice(this.currentHistoryItem + 1);
        }
        this.history.push(JSON.parse(JSON.stringify(this.export())));
        this.currentHistoryItem = this.history.length - 1;
    }

    private testConnect(model2d: Instance2dData, ignore: string[] | null): any {
        const connectorCount = model2d.getConnectorCount();
        const existingConnected = [];
        const newConnectors = [];
        for (let i = 0; i < connectorCount; ++i) {
            const location = model2d.getAbsoluteConnectorPosition(i);
            const existing = this._poles.find(location, 50);
            if (existing !== null) {
                existingConnected[i] = existing;
            } else {
                newConnectors.push(location);
            }
        }
        const connectedElements: any[] = [];
        const connectable = model2d.getConnectable();
        for (let i = 0; i < connectorCount; ++i) {
            if (connectable[i] && i in existingConnected && ((i + 1) % connectorCount) in existingConnected) {
                const poleConnected = existingConnected[i];
                const poleConnectedOther = existingConnected[(i + 1) % connectorCount];
                for (const connector of poleConnected.connectedElements) {
                    for (const connectorOther of poleConnectedOther.connectedElements) {
                        if (connector.playgroundElement === connectorOther.playgroundElement
                            && connectorOther.playgroundElement.getConnectable()[connectorOther.connector]
                            && (connector.connector === (1 + connectorOther.connector)
                                % connectorOther.playgroundElement.model2d.getConnectorCount())) {
                            if (!ignore || ignore.indexOf(connectorOther.playgroundElement.uid) === -1) {
                                connectedElements.push({
                                    otherElement: connectorOther.playgroundElement,
                                    otherConnector: connectorOther.connector,
                                    connector: i,
                                });
                            }
                        }
                    }
                }
            }
        }
        return {poles: existingConnected, elements: connectedElements, newPoleLocations: newConnectors};
    }

    private async handlePoles(newElement: PlaygroundElement, mainConnector?: number) {
        const connectorCount = newElement.model2d.getConnectorCount();
        const existingConnected = [];
        for (let i = 0; i < connectorCount; ++i) {
            const location = newElement.model2d.getAbsoluteConnectorPosition(i);
            const existing = this._poles.find(location, 50);
            if (existing !== null) {
                existing.connect(newElement, i);
                newElement.addPole(existing);
                existingConnected[i] = existing;
                // } else if (place) {
            } else {
                const newPole = new Pole(newElement, i, this);
                this._poles.add(newPole.location, newPole);
                newElement.addPole(newPole);
                await this.world3d.addPole(newPole);
            }
        }
        if (connectorCount > 2) {
            const connectable = newElement.getConnectable();
            for (let i = 0; i < connectorCount; ++i) {
                if (connectable[i] && i !== mainConnector && i in existingConnected
                    && ((i + 1) % connectorCount) in existingConnected) {
                    const poleConnected = existingConnected[i];
                    const poleConnectedOther = existingConnected[(i + 1) % connectorCount];
                    for (const connector of poleConnected.connectedElements) {
                        for (const connectorOther of poleConnectedOther.connectedElements) {
                            if (connector.playgroundElement === connectorOther.playgroundElement
                                && connector.playgroundElement !== newElement
                                && connectorOther.playgroundElement.getConnectable()[connectorOther.connector]
                                && (connector.connector === (1 + connectorOther.connector)
                                    % connectorOther.playgroundElement.model2d.getConnectorCount())) {
                                newElement.connect(i, connectorOther.playgroundElement, connectorOther.connector);
                                if (newElement.isFloor && connectorOther.playgroundElement.isFloor) {
                                    const step = new Step(newElement, i, connectorOther.playgroundElement,
                                        connectorOther.connector, this, this.utilsPlayground);
                                    await step.update();
                                    this.steps.push(step);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private exportSteps(): any[] {
        const result: any[] = [];
        const tmp: any = {};
        for (const step of this.steps) {
            // const id = Math.abs(step.dt);
            const id = step.stepId;
            if (step.exists() && id != null) {
                if (!(id in tmp)) {
                    const count: any = {count: 0, partSpecId: id};
                    tmp[id] = count;
                    result.push(count);
                }
                ++tmp[id].count;
            }
        }
        return result;
    }

    private async directDeleteElement(uid: string): Promise<PlaygroundElement> {
        const element = this.elements.get(uid);
        if (element === undefined) {
            throw new Error('Unknown uid');
        }
        element.deleteConnections();
        this._world2d.deleteElement(element.model2d);
        this._world3d.deleteElement(await element.getModel3d());
        if (this.main === element) {
            this.main = undefined;
        }
        for (let i = 0; i < this.steps.length; ++i) {
            const step = this.steps[i];
            if (step.matches(element)) {
                this.steps.splice(i, 1);
                await step.delete();
                --i;
            }
        }
        this.elements.delete(element.uid);
        if (!this.main && this.elements.size > 0) {
            this.main = this.elements.values().next().value;
        }
        return element;
    }

    private async testFit(type: number, heightOffset: number, loadedItem: boolean, connector: number,
                          connectedToInstance: string, connectedToConnector: number, ignore: string[] | null,
                          requiredConnections?: string[]): Promise<boolean> {
        const testElement = await (loadedItem ? this.utilsPlayground.getInstance(type, heightOffset, this,
            this.utilsPlayground.generateUid())
            : this.utilsPlayground.getItem(type, heightOffset, this, this.utilsPlayground.generateUid()));
        const roof = testElement.isRoof;
        const table = testElement.isTable;
        const test2dElement = testElement.model2d;
        const connectToElement = this.elements.get(connectedToInstance);
        if (connectToElement === undefined) {
            throw new Error('Unknown uid');
        }
        let result = true;
        if (!testElement.isBench) {
            if (!testElement.isFloor && !connectToElement.isFloor) {
                throw new Error('Must connect to a floor');
            }
            this._world2d.position(test2dElement, connector, connectToElement.model2d, connectedToConnector);
            if (!loadedItem) {
                heightOffset += test2dElement.zero - test2dElement.getConnectorAltitude(connector);
            }
            if (!testElement.isAllowedAltitude(heightOffset)) {
                result = false;
            }
            testElement.heightOffset = heightOffset;

            const testConnect: any = this.testConnect(test2dElement, ignore);
            if (!roof && !table) {
                for (const element of testConnect.elements) {
                    const otherElement = element.otherElement;
                    const heightDiff = Math.abs(heightOffset + test2dElement.getConnectorOffset(element.connector)
                        - otherElement.connectorAltitude(element.otherConnector));
                    if (otherElement.isBench || otherElement.hasBenchAtConnector(element.otherConnector)) {
                        result = result && testElement.canConnectBenchAtConnector(
                            element.connector,
                            test2dElement.connectors[element.connector].sizeGroup,
                        );
                    }
                    let allowedHeightDiff = Playground.MAX_ALT_DIFF;
                    if (!testElement.isFloor || !element.otherElement.isFloor) {
                        allowedHeightDiff = 0;
                    }
                    if (otherElement.isBench) {
                        result = result && heightDiff >= 1200;
                    } else {
                        result = result && heightDiff <= allowedHeightDiff;
                    }
                }
            }
            if (result) {
                for (const element of testConnect.elements) {
                    result = result && test2dElement.connectors[element.connector].sizeGroup ===
                        element.otherElement.model2d.connectors[element.otherConnector].sizeGroup;

                    // In case of elements with multiple connectors that are different in height:
                    // make sure that the remaining connector has an allowed height.
                    const testElementConnector = test2dElement.connectors[element.connector];
                    const remainingConnectors = test2dElement.connectors.filter((remainingConnector: Connector) => {
                        return remainingConnector.group !== testElementConnector.group
                            && remainingConnector.connectable;
                    });
                    const otherConnectorOffset = element.otherElement.connectorAltitude(element.otherConnector);
                    for (const remainingConnector of remainingConnectors) {
                        const altitudeAtRemainingConnector = otherConnectorOffset + remainingConnector.altitude
                            - testElementConnector.altitude;
                        if (!testElement.partSpec.heights.map((heightOb: IHeight) => heightOb.height)
                            .includes(altitudeAtRemainingConnector)) {
                            result = false;
                        }
                    }
                }
            }
            if (requiredConnections) {
                const uidConnections = testConnect.elements.map((element: any) => element.otherElement.uid);
                for (const uid of requiredConnections) {
                    result = result && uidConnections.indexOf(uid) !== -1;
                }
            }
            if (result) {
                if (roof) {
                    if (testConnect.newPoleLocations.length > 0) {
                        result = false;
                    }
                    for (let i = 0; i < testConnect.poles.length && result; ++i) {
                        const pole = testConnect.poles[i];
                        for (const element of pole.connectedElements) {
                            result = result && !element.playgroundElement.isRoof;
                        }
                    }
                } else if (table) {
                    if (testConnect.newPoleLocations.length > 0) {
                        result = false;
                    }
                    let nrOfPolesThatAreOccupiedByATable = 0;
                    for (let i = 0; i < testConnect.poles.length && result; ++i) {
                        const pole = testConnect.poles[i];
                        for (const element of pole.connectedElements) {
                            if (element.playgroundElement.isTable) {
                                nrOfPolesThatAreOccupiedByATable++;
                            }
                        }
                    }
                    result = result && nrOfPolesThatAreOccupiedByATable < testConnect.poles.length;
                } else {
                    for (const newPoleLocation of testConnect.newPoleLocations) {
                        const from = new Vector2(newPoleLocation.x - Playground.POLE_DISTANCE,
                            newPoleLocation.y - Playground.POLE_DISTANCE);
                        const to = new Vector2(newPoleLocation.x + Playground.POLE_DISTANCE,
                            newPoleLocation.y + Playground.POLE_DISTANCE);
                        for (const pole of this._poles.findRange(from, to)) {
                            const distance = pole.location.distanceTo(newPoleLocation);
                            if (distance < Playground.POLE_DISTANCE) {
                                if (!ignore || pole.connectedElements.length > 1
                                    || ignore.indexOf(pole.connectedElements[0].playgroundElement.uid) === -1) {
                                    result = false;
                                }
                            }
                        }
                    }
                }
            }
            for (const element of this.elements.values()) {
                if (!ignore || ignore.indexOf(element.uid) === -1) {
                    result = result && !element.collide(testElement);
                }
            }
        } else {
            result = connectToElement.canConnectBenchAtConnector(
                connectedToConnector,
                test2dElement.connectors[0].sizeGroup,
            );
        }
        return result;
    }

    private fullCleanup() {
        this.history.splice(0);
        this.currentHistoryItem = -1;
        this.id = null;
        this.setDampeningFloor(null);
        this._cleanup();
    }
}
