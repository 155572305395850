import {IDampeningFloor} from '@/model/DampeningFloor';
import {Idable} from '@/model/Idable';
import {FileInputResource} from '@/model/Part';
import {IHeight} from '@/model/PartProperties';
import {IPartSpec} from '@/model/PartSpec';
import {IUser} from '@/model/User';
import {IColorTheme} from '@/model/ColorTheme';

export enum PlaygroundStatus {
    CONCEPT = 1,
    NEW = 2,
    WAITING_FOR_APPROVAL = 3,
    APPROVED = 4,
    ORDERED = 5,
    READY_FOR_ASSEMBLY = 6,
    DELIVERED = 7,
    CANCELED = 8,
}

export const playgroundStatuses: Map<PlaygroundStatus, string> = new Map<PlaygroundStatus, string>([
    [PlaygroundStatus.CONCEPT, 'Concept'],
    [PlaygroundStatus.NEW, 'Offerte aangevraagd'],
    [PlaygroundStatus.WAITING_FOR_APPROVAL, 'In behandeling'],
    [PlaygroundStatus.APPROVED, 'Goedgekeurd'],
    [PlaygroundStatus.ORDERED, 'Besteld'],
    [PlaygroundStatus.READY_FOR_ASSEMBLY, 'Klaar voor assemblage'],
    [PlaygroundStatus.DELIVERED, 'Opgeleverd'],
    [PlaygroundStatus.CANCELED, 'Geannuleerd'],
]);

export interface IDampingFloorPlacement {
    type: number;
    dampingFloorArea: number;
}

export interface IPlaygroundPole {
    size: number;
    count: number;
}

export interface IPolePrice extends Idable {
    size: number;
    price: number;
}

export interface IPoleConfig extends Idable {
    gltf: string | null;
    bin: string | null;
    prices: IPolePrice[];
}

export interface IPlaygroundStep {
    partSpecId: number;
    count: number;
}

export interface IPartColor {
    groupName: string;
    color: string;
}

export interface IPartInstance extends Idable {
    partSpec: IPartSpec;
    colors: IPartColor[];
    height: IHeight;
}

export interface IPlayground extends Idable {
    user: Idable;
    state: PlaygroundStatus;
    name: string;
    description?: string;
    location?: string;
    quotationPrice?: number;
    constructionPrice?: number;
    template: boolean;
    icon?: string;
    customIcon?: string;
    comment?: string;
    internalComment?: string;
    maxHeight?: number;
    numberOfEntrances?: number;
    numberOfSlides?: number;

    deltaRotation?: number;
    perpendicular?: boolean;

    colorTheme?: IColorTheme;

    floor?: IDampingFloorPlacement;
    poles?: IPlaygroundPole[];
    steps?: IPlaygroundStep[];
    parts?: IPartInstance[];

    assignedMechanics?: IUser[];

    // Local helpers
    customIconObject?: FileInputResource;
    duplicate?: boolean;

}
