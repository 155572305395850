import {PlaygroundElement} from '@/model/playground/playgroundElement';

export class ElementAttacher {
    constructor(private _fromConnector: number, private _connectToElement: PlaygroundElement,
                private _toConnector: number) {
    }

    get fromConnector(): number {
        return this._fromConnector;
    }

    get connectToElement(): PlaygroundElement {
        return this._connectToElement;
    }

    get toConnector(): number {
        return this._toConnector;
    }

    public export() {
        return {
            fromConnector: this._fromConnector,
            toConnector: this._toConnector,
            toElement: this._connectToElement.uid,
        };
    }
}
