
    import {Component, Vue} from 'vue-property-decorator';
    import Modal from '@/components/Modal.vue';
    import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
    import {PlaygroundApi} from '@/api/playground';
    import {State} from 'vuex-class';
    import PlaygroundCard from '@/components/PlaygroundCard.vue';
    import InputField from '@/components/InputField.vue';
    import {IPlayground, PlaygroundStatus} from '@/model/Playground';
    import {Playground} from '@/model/playground/playground';
    import {PlaygroundSaveHandler} from '@/model/playground/playgroundSaveHandler';

    @Component({
        components: {InputField, PlaygroundCard, CloseCircleIcon, Modal},
    })
    export default class PlaygroundSaveModal extends Vue {
        private playgroundApi: PlaygroundApi = new PlaygroundApi();
        @State((state: any) => state.authentication.authenticated)
        private isAuthenticated!: boolean;
        private name = '';
        private description = '';
        private id?: number;
        private fieldErrors: any = {
            name: null,
            description: null,
        };

        private async created() {
            if (this.isAuthenticated) {
                let playgroundInfo: IPlayground | null = this.$store.state.playground.playgroundInfo;
                const storedPlaygroundExport = this.$store.state.playground.playgroundExport;
                if (!playgroundInfo && storedPlaygroundExport) {
                    playgroundInfo = JSON.parse(storedPlaygroundExport);
                }
                if (playgroundInfo) {
                    this.name = playgroundInfo.name;
                    this.description = playgroundInfo.description || '';
                    this.id = playgroundInfo.id;
                }
            }
        }

        private validate(): boolean {
            this.fieldErrors.name = null;
            this.fieldErrors.description = null;
            if (!this.name) {
                this.fieldErrors.name = 'Naam voor speeltoestel is verplicht.';
                return false;
            }
            if (this.name.length > 40) {
                this.fieldErrors.name = 'De naam mag uit maximaal 40 karakters bestaan';
                return false;
            }
            if (this.description.length > 255) {
                this.fieldErrors.description = 'De omschrijving mag maximaal 255 karakters bevatten';
                return false;
            }
            return true;
        }

        private openDashboard() {
            this.$router.push('dashboard');
            this.$emit('close');
        }

        private login() {
            this.$router.push({name: 'login', query: {reason: 'save'}});
            this.$emit('close');
        }

        private async save() {
            const playground: any = this.$store.state.playground.playground;
            if (!(playground instanceof Playground)) {
                return;
            }
            if (!this.validate()) {
                return;
            }

            const playgroundInfo: IPlayground = playground.export();
            if (!playgroundInfo) {
                return;
            }
            playgroundInfo.name = this.name;
            playgroundInfo.description = this.description;
            playgroundInfo.state = PlaygroundStatus.CONCEPT; // concept
            playgroundInfo.id = this.id;

            const playgroundSaveHandler = new PlaygroundSaveHandler(this.$api.playground);
            const newPlaygroundInfo = await playgroundSaveHandler.save(playgroundInfo, playground);
            if (newPlaygroundInfo) {
                this.$store.commit('setPlaygroundInfo', newPlaygroundInfo);
            }
            this.$store.commit('setPlayground', this.$store.state.playground.playground);
            this.$store.commit('setPlaygroundHasChanges', false);
            this.$emit('saved');
        }
    }
