import {Vector2} from 'three';
import {VectorMath} from '@/model/2d/vectorMath';

// inspiration http://geomalgorithms.com/a10-_hull-1.html

export class MinimalBoundingBox {
    private _minimumBoundingBoxUnrotated: Vector2[] = [];
    private _minimalBoundingBox: Vector2[] = [];
    private _rotationPoint!: Vector2;
    private _rotation = 0;
    private origin: Vector2 | null = null;
    private convexHulls: Vector2[] = [];
    private items: Vector2[] = [];
    private _svg = '';

    public addItem(item: Vector2) {
        if (this.origin === null || this.origin.y > item.y || (this.origin.y === item.y && this.origin.x < item.x)) {
            this.origin = item;
        }
        this.items.push(item);
    }

    get minimalBoundingBox(): Vector2[] {
        return this._minimalBoundingBox;
    }

    get svg(): string {
        return this._svg;
    }

    public getDirectionalBoundingBox(side: number, center?: Vector2): Vector2[] | null {
        center = center || new Vector2();
        let lower: Vector2 | null = null;
        let upper: Vector2 | null = null;
        if (this.convexHulls.length === 0) {
            this.calculateConvexHull();
        }
        for (const item of this.convexHulls) {
            const position = item.clone().rotateAround(center, side);
            if (lower === null) {
                lower = position.clone();
            }
            if (upper === null) {
                upper = position.clone();
            }
            if (lower && lower.x > position.x) {
                lower.x = position.x;
            }
            if (lower && lower.y > position.y) {
                lower.y = position.y;
            }
            if (upper && upper.x < position.x) {
                upper.x = position.x;
            }
            if (upper && upper.y < position.y) {
                upper.y = position.y;
            }
        }
        if (lower && upper) {
            return [lower, upper];
        } else {
            return null;
        }
    }

    public calculate(rotation?: number, optimize = true) {
        this._svg = '';
        this.calculateConvexHull();
        this._minimalBoundingBox = this.calculateBoxes(rotation, optimize);
    }

    get rotationPoint(): Vector2 {
        return this._rotationPoint;
    }

    private calculateConvexHull() {
        if (this.origin && this.items.length > 1) {
            const origin = this.origin;
            const sorted = this.items.slice(0);
            sorted.splice(sorted.indexOf(origin), 1);
            sorted.sort((a, b) => {
                let result = this.isLeft(origin, a, b);
                if (result === 0) {
                    result = this.farther(origin, a, b);
                }
                return result;
            });
            this.convexHulls = [];
            this.convexHulls.push(origin);
            this.convexHulls.push(sorted[0]);
            this._svg += '<text x="' + origin.x + '" y="' + origin.y + '">Or</text>';
            this._svg += '<text x="' + sorted[0].x + '" y="' + sorted[0].y + '">0</text>';
            for (let i = 1; i < sorted.length; ++i) {
                if (this.isLeft(origin, this.convexHulls[this.convexHulls.length - 1], sorted[i]) !== 0) {
                    let notAdded = true;
                    while (notAdded) {
                        if (this.convexHulls.length > 1) {
                            const t1 = this.convexHulls[this.convexHulls.length - 1];
                            const t2 = this.convexHulls[this.convexHulls.length - 2];
                            if (this.isLeft(sorted[i], t1, t2) > 0) {
                                this.convexHulls.push(sorted[i]);
                                notAdded = false;
                            } else {
                                this.convexHulls.pop();
                            }
                        } else {
                            this.convexHulls.push(sorted[i]);
                            notAdded = false;
                        }
                    }
                }
                this._svg += '<text x="' + sorted[i].x + '" y="' + sorted[i].y + '">' + i + '</text>';
                // console.log('add units', i, sorted.length);
            }
            this._svg += '<polygon points="' + this.convexHulls.map((point) => '' + point.x + ',' + point.y).join(',')
                + ' ' + '" style="fill:none;stroke:mediumspringgreen;stroke-width:100"/>';
        }
    }

    private size(input: Vector2[]) {
        return (input[1].x - input[0].x) * (input[1].y - input[0].y);
    }

    private calculateBoxes(rotation?: number, optimize = true) {
        let smallestPolygon: Vector2[] = [];
        let smallest = 0;
        let smallestSize = -1;
        let smallestBox: Vector2[] = [];
        let smallestCorner = 0;
        for (let i = 0; i < this.convexHulls.length; ++i) {
            let corner = rotation ? rotation * Math.PI / 180 : 0;
            if (!corner && optimize) {
                corner = VectorMath.corner(this.convexHulls[i], this.convexHulls[(i + 1) % this.convexHulls.length]);
            }
            if (rotation != null || (corner >= 0 && corner <= 90)) {
                const box = this.getMinBox(this.convexHulls[i], -corner);
                const size = this.size(box);
                if (smallest === 0 || smallestSize > size) {
                    smallest = i;
                    smallestBox = box;
                    smallestSize = size;
                    smallestCorner = corner;
                }
            }
        }
        if (smallestSize !== -1) {
            this._minimumBoundingBoxUnrotated = smallestBox;
            this._rotation = smallestCorner;
            this._rotationPoint = this.convexHulls[smallest];
            smallestPolygon = [smallestBox[0].clone().rotateAround(this.convexHulls[smallest], smallestCorner),
                new Vector2(smallestBox[0].x, smallestBox[1].y)
                    .rotateAround(this.convexHulls[smallest], smallestCorner),
                smallestBox[1].clone().rotateAround(this.convexHulls[smallest], smallestCorner),
                new Vector2(smallestBox[1].x, smallestBox[0].y)
                    .rotateAround(this.convexHulls[smallest], smallestCorner),
                // this.convexHulls[smallest]
            ];
            this._svg += '<polygon points="' + smallestPolygon.map((point) => '' + point.x + ',' + point.y).join(',')
                + ' ' + '" style="fill:none;stroke:crimson;stroke-width:100"/>';
        }
        return smallestPolygon;
    }

    get minimumBoundingBoxUnrotated(): Vector2[] {
        return this._minimumBoundingBoxUnrotated;
    }

    get rotation(): number {
        return this._rotation;
    }

    private getMinBox(origin: Vector2, rotation: number): Vector2[] {
        const min = origin.clone();
        const max = origin.clone();

        for (const convexHull of this.convexHulls) {
            if (convexHull !== origin) {
                const testable = convexHull.clone().rotateAround(origin, rotation);
                if (min.x > testable.x) {
                    min.x = testable.x;
                }
                if (min.y > testable.y) {
                    min.y = testable.y;
                }
                if (max.x < testable.x) {
                    max.x = testable.x;
                }
                if (max.y < testable.y) {
                    max.y = testable.y;
                }
            }
        }
        return [min, max];
    }

    private farther(P0: Vector2, P1: Vector2, P2: Vector2): number {
        const x1 = P0.x - P1.x;
        const y1 = P0.y - P1.y;
        const x2 = P0.x - P2.x;
        const y2 = P0.y - P2.y;
        return (x2 * x2 + y2 * y2) - (x1 * x1 + y1 * y1);
    }

    private isLeft(P0: Vector2, P1: Vector2, P2: Vector2): number {
        return ((P1.x - P0.x) * (P2.y - P0.y) - (P2.x - P0.x) * (P1.y - P0.y));
    }
}
