
    import {Component, Vue} from 'vue-property-decorator';
    import Modal from '@/components/Modal.vue';
    import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
    import PlaygroundCard from '@/components/PlaygroundCard.vue';
    import InputField from '@/components/InputField.vue';
    import PlaygroundPriceTable from '@/components/PlaygroundPriceTable.vue';
    import {Playground} from '@/model/playground/playground';
    import {State} from 'vuex-class';
    import {IPlayground, PlaygroundStatus} from '@/model/Playground';
    import {IUser} from '@/model/User';

    @Component({
        components: {PlaygroundPriceTable, InputField, PlaygroundCard, CloseCircleIcon, Modal},
    })
    export default class PlaygroundPriceModal extends Vue {
        @State((state) => state.playground.playground)
        private playground!: Playground | null;
        @State((state) => state.playground.playgroundInfo)
        private playgroundInfo!: IPlayground | null;
        @State((state: any) => state.authentication.user)
        private currentUser!: IUser;
        private hasRequiredOpenConnectors = true;

        private get canRequestQuote() {
            // unlike in the editors isReadOnly, ignore user role, no quotes for quoted playgrounds
            return !this.playgroundInfo
                || (this.playgroundInfo.template || (this.playgroundInfo.state === PlaygroundStatus.CONCEPT
                    && this.playgroundInfo.user &&  this.playgroundInfo.user.id === this.currentUser.id));
        }

        private async created() {
            if (this.playground) {
                this.hasRequiredOpenConnectors = !this.playground.hasEntrance()
                    || await this.playground.hasOpenRequiredConnectors();
            }
        }

        private submitRequest() {
            if (this.hasRequiredOpenConnectors) {
                return;
            }
            this.$emit('close');
            this.$router.push({name: 'request_offer'});
        }
    }
