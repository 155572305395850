import {http, url} from '@/utils/http';
import {IWrapperResponse} from '@/api/generic';
import {IContact} from '@/model/Contact';

/**
 * Api Accessor for Contact
 */
export class ContactApi {
    public async sendMessage(contact: IContact) {
        const response = await http.post(url('api/v1/contact'), contact);
        return response.data as IWrapperResponse<boolean>;
    }
}
