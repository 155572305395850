
    import {Component, Vue} from 'vue-property-decorator';
    import Card from '@/components/Card.vue';
    import PlaygroundCard from '@/components/PlaygroundCard.vue';
    import FolderSearchOutlineIcon from 'vue-material-design-icons/FolderSearchOutline.vue';
    import Modal from '@/components/Modal.vue';
    import InputField from '@/components/InputField.vue';
    import FolderSearchIcon from 'vue-material-design-icons/FolderSearch.vue';
    import NewBoxIcon from 'vue-material-design-icons/NewBox.vue';
    import {PlaygroundApi} from '@/api/playground';
    import {Pagination} from '@/utils/api-tools/pagination';
    import {State} from 'vuex-class';
    import {IPlayground} from '@/model/Playground';
    import PlaygroundDetailsCard from '@/components/PlaygroundDetailsCard.vue';

    @Component({
        components: {
            PlaygroundDetailsCard,
            FolderSearchIcon,
            NewBoxIcon,
            InputField,
            Modal,
            FolderSearchOutlineIcon,
            PlaygroundCard,
            Card,
        },
    })
    export default class Home extends Vue {
        @State((state: any) => state.authentication.authenticated)
        public isAuthenticated!: boolean;
        private playgroundApi: PlaygroundApi = new PlaygroundApi();
        private playgroundTemplates: IPlayground[] = [];
        private searchPlayground = '';

        public async created() {
            const playgroundsApiData: any = await this.playgroundApi.getPlaygroundTemplates(new Pagination(0, -1), []);
            if (playgroundsApiData) {
                this.playgroundTemplates = playgroundsApiData.data.content;
            }
        }

        get filteredPlaygroundTemplates() {
            return this.playgroundTemplates.length
                ? this.playgroundTemplates.filter((playground: IPlayground) => {
                        return !this.searchPlayground
                            || playground.name.toLowerCase().includes(this.searchPlayground.toLowerCase());
                    })
                : []
            ;
        }
    }
