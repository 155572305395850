/**
 * A criterion to search by. Can be used for any accessible property.
 * a '*' denotes a search over all available properties.
 */
export interface ISearchCriterion {
    key: string;
    value: string;
}

export class SearchCriterion implements ISearchCriterion {

    public static serialize(searchCriterion: ISearchCriterion) {
        const params = new URLSearchParams();
        params.append('search', `${searchCriterion.key}:${searchCriterion.value}`);
        return params;
    }

    public readonly key: string;
    public readonly value: string;

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }

    public serialize() {
        return SearchCriterion.serialize(this);
    }
}
