import {Playground} from '@/model/playground/playground';
import {IPoleConfig} from '@/model/Playground';
import {IPart} from '@/model/Part';
import {PartCategoryType} from '@/model/PartCategory';
import {IPartSpec} from '@/model/PartSpec';

export class PlaygroundPriceCalculator {
    private poleConfig: IPoleConfig | null = null;
    private parts: IPart[] = [];
    private certificationCosts = 0;

    constructor(poleConfig: IPoleConfig, parts: IPart[], certificationCosts: number) {
        this.poleConfig = poleConfig;
        this.parts = parts;
        this.certificationCosts = certificationCosts;
    }

    public setParts(parts: IPart[]) {
        this.parts = parts;
    }

    public setPoleConfig(poleConfig: IPoleConfig) {
        this.poleConfig = poleConfig;
    }

    public getPrice(playground: Playground) {
        let totalPrice = this.certificationCosts;
        for (const element of playground.playgroundElements.values()) {
            totalPrice += element.partSpec.price;
        }
        // Currently the dampingFloorPrice is not included in price calculation.
        // const dampingFloorPrice = this.getTotalDampingFloorPrice(playground);
        // totalPrice +=  dampingFloorPrice ? dampingFloorPrice : 0;
        const totalPolesPrice = this.getTotalPolesPrice(playground);
        totalPrice +=  totalPolesPrice ? totalPolesPrice : 0;
        const totalStepsPrice = this.getTotalStepsPrice(playground);
        totalPrice +=  totalStepsPrice ? totalStepsPrice : 0;
        return totalPrice;
    }

    public getTotalDampingFloorPrice(playground: Playground): number | null {
        if (playground.dampeningFloor && playground.dampeningFloor.tileWidth && playground.dampeningFloor.tileHeight) {
            const fallSurfaceVectors = playground.getFallSurfaceBoundingBoxVectors();
            const width = fallSurfaceVectors[0].distanceTo(fallSurfaceVectors[1]);
            const height = fallSurfaceVectors[0].distanceTo(fallSurfaceVectors[3]);
            const tilesPerWidth = Math.ceil(width / playground.dampeningFloor.tileWidth);
            const tilesPerHeight = Math.ceil(height / playground.dampeningFloor.tileHeight);
            return playground.dampeningFloor.price * tilesPerWidth * tilesPerHeight;
        }
        return null;
    }

    public getTotalPolesPrice(playground: Playground): number {
        let price = 0;
        const poles = this.getPlaygroundPoles(playground);
        if (!poles) {
            return price;
        }
        for (const pole of poles) {
            const polePrice = this.getPolePrice(pole.size);
            price += polePrice ? polePrice * pole.count : 0;
        }
        return price;
    }

    public getPolePrice(poleSize: number): number | null {
        if (!this.poleConfig) {
            return null;
        }
        let closestMatch = null;
        for (const polePrice of this.poleConfig.prices) {
            if (polePrice.size >= poleSize && (!closestMatch || polePrice.size < closestMatch.size)) {
                closestMatch = polePrice;
            }
        }
        if (closestMatch) {
            return closestMatch.price;
        }
        return null;
    }

    public getTotalStepsPrice(playground: Playground): number {
        let price = 0;
        const steps = this.getPlaygroundSteps(playground);
        if (!steps) {
            return price;
        }
        for (const step of steps) {
            const stepPrice = this.getStepPrice(step.height!, step.partSpecId);
            price += stepPrice ? stepPrice * step.count : 0;
        }
        return price;
    }

    public getStepPrice(height: number, partSpecId?: number): number | null {
        let closestMatchingHeight = null;
        let closestMatchingPartSpec = null;

        const parts = this.parts || [];
        const stepParts = parts.filter((part) => part.category.type === PartCategoryType.STEP);

        if (partSpecId) {
            const partSpecs = this.parts
                .filter((p: IPart) => p.category.type === PartCategoryType.STEP)
                .map((p: IPart) => p.partSpecs)
            ;
            const part = [].concat(...(partSpecs as any))
                .find((partSpec: IPartSpec) => partSpec.id === partSpecId)
            ;
            if (part) {
                return (part as IPartSpec).price;
            } else {
                return null;
            }
        } else {
            for (const part of stepParts) {
                for (const partSpec of part.partSpecs) {
                    for (const partSpecHeight of partSpec.heights) {
                        if (partSpecHeight.height >= height &&
                            (!closestMatchingHeight || partSpecHeight.height < closestMatchingHeight.height)) {
                            closestMatchingHeight = partSpecHeight;
                            closestMatchingPartSpec = partSpec;
                        }
                    }
                }
            }
            return closestMatchingPartSpec ? closestMatchingPartSpec.price : 0;
        }
    }

    private getPlaygroundPoles(playground: Playground | null): Array<{count: number, size: number}> {
        return playground ? playground.export().poles : [];
    }

    private getPlaygroundSteps(playground: Playground | null):
        Array<{ count: number, height?: number, partSpecId?: number; }> {
        return playground ? playground.export().steps : [];
    }
}
