import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IPartCategory} from '@/model/PartCategory';
import {IAgeCategory, IDifficultyCategory, IHeight, IMaterial} from '@/model/PartProperties';
import {http, url} from '@/utils/http';
import {IPagination} from '@/utils/api-tools/pagination';
import {ISearchCriterion} from '@/utils/api-tools/search-criteria';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IPart} from '@/model/Part';

export class PartApi {
    public async getParts(pagination: IPagination, search: ISearchCriterion[]):
        Promise<IWrapperResponse<IPaginationResponse<IPart>>> {
        return new GenericApi<IPart>('/api/v1/part').list(pagination, search);
    }

    public async getPartCategories() {
        const uri = new URL(url('/api/v1/part/part-category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IPartCategory[]>;
    }

    public async getAgeCategories() {
        const uri = new URL(url('/api/v1/part/age-category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IAgeCategory[]>;
    }

    public async getDifficultyCategories() {
        const uri = new URL(url('/api/v1/part/difficulty-category'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IDifficultyCategory[]>;
    }

    public async getHeights() {
        const uri = new URL(url('/api/v1/part/height'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IHeight[]>;
    }

    public async getMaterials() {
        const uri = new URL(url('/api/v1/part/material'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IMaterial[]>;
    }

    public async getFillFenceId() {
        const uri = new URL(url('/api/v1/fill-fence'));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<number>;
    }
}
