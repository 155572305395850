export type SortDirection = 'DESC'|'ASC';

export interface ISort {
    sort: string;
    direction: SortDirection;
}

export interface IPagination {
    page: number;
    size: number;
    sort?: ISort;
}

export class Pagination implements IPagination {

    public static serialize(pagination: IPagination) {
        const params = new URLSearchParams();
        params.append('page', String(pagination.page));
        params.append('size', String(pagination.size));
        if (pagination.sort && !!pagination.sort.sort) {
            params.append('sort', `${pagination.sort.sort},${pagination.sort.direction}`);
        }
        return params;
    }

    public page = 0;
    public size = 20;
    public sort?: ISort;

    constructor(page: number, size: number, sort = 'id', direction: SortDirection = 'DESC') {
        this.page = page;
        this.size = size > 0 ? size : 1000; // Limit to 1000.
        this.sort = {sort, direction};
    }

    public serialize(): URLSearchParams {
        return Pagination.serialize(this);
    }
}
