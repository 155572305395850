import {Vector2} from 'three';

export class MapEntry<T> {
    private readonly _location: Vector2;
    private readonly _value: T;

    constructor(location: Vector2, value: T) {
        this._location = location;
        this._value = value;
    }

    get location(): Vector2 {
        return this._location;
    }

    get value(): any {
        return this._value;
    }
}
