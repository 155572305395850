export enum BalloonName {
    PRICE = 'price',
    LOGIN_TO_SAVE = 'login_to_save',

    EXPAND_ARROW = 'expand_arrow',
    MODIFY_PART = 'modify_part',
    ALTITUDE = 'altitude',
    BRIDGE = 'bridge',
    BRIDGE_BEFORE_FLOOR_SELECTION = 'bridge_before_floor_selection',

    MEASURES = 'measures',
    MOUSE_INFO = 'mouse_info',

    SELECT_FIRST_PART = 'select_first_part',
    FEW_PARTS = 'few_parts',
    BENCH_PARTS = 'bench_parts',
    SELECT_PART = 'select_part',
    SELECT_BENCH = 'select_bench',
    SELECT_ROOF = 'select_roof',
    SELECT_TABLE = 'select_table',
}

export interface TemporaryBalloon {
    balloonName: BalloonName;
    displayElementChangeCount: number;
}

// Balloons in the same group should not be shown simultaneously.
const balloonGroups: {[key: string]: BalloonName[]} = {
    menu: [BalloonName.PRICE, BalloonName.LOGIN_TO_SAVE],
    below_2d_options: [BalloonName.MEASURES, BalloonName.MOUSE_INFO],
    world_2d: [BalloonName.EXPAND_ARROW, BalloonName.MODIFY_PART, BalloonName.ALTITUDE, BalloonName.BRIDGE],
    above_part_selector: [BalloonName.SELECT_FIRST_PART, BalloonName.FEW_PARTS, BalloonName.BENCH_PARTS,
        BalloonName.SELECT_BENCH, BalloonName.SELECT_ROOF, BalloonName.SELECT_TABLE],
};

// Balloons that are allowed to be showed multiple times.
const repeatableBalloons = [
    BalloonName.SELECT_BENCH, BalloonName.SELECT_ROOF, BalloonName.SELECT_TABLE,
];

// Balloons that should only be shown when guide is enabled (e.g. when someone is creating a new playground).
const guideBalloons = [
    BalloonName.EXPAND_ARROW, BalloonName.MODIFY_PART, BalloonName.ALTITUDE, BalloonName.BRIDGE, BalloonName.MEASURES,
    BalloonName.MOUSE_INFO, BalloonName.SELECT_FIRST_PART, BalloonName.BENCH_PARTS, BalloonName.FEW_PARTS,
    BalloonName.PRICE,
];

export class BalloonManager {
    private _active = true;
    private _guideMode = false;
    private _show: TemporaryBalloon[] = [];
    private _alreadyShown: TemporaryBalloon[] = [];
    private elementChangeCount: number = 0;
    private balloonLifeTimeInElementChanges = 5;

    public initiate() {
        this._active = true;
        this._guideMode = true;
        this.clear();
    }

    public clear() {
        this._show = [];
        this._alreadyShown = [];
        this.elementChangeCount = 0;
    }

    public addElementChange() {
        this.elementChangeCount++;
        this._show.filter(item => item.displayElementChangeCount <= this.elementChangeCount).forEach(item => {
            if (item.displayElementChangeCount + this.balloonLifeTimeInElementChanges < this.elementChangeCount) {
                this.removeBalloon(item.balloonName);
            }
        });
    }

    public removeSelectBalloons() {
        this.selectFirstPart = false;
        this.selectTable = false;
        this.selectBench = false;
        this.selectRoof = false;
        this.selectPart = false;
    }

    get active() {
        return this._active;
    }
    set active(value: boolean) {
        this._active = value;
    }

    get guideMode() {
        return this._guideMode;
    }
    set guideMode(value: boolean) {
        this._guideMode = value;
    }

    get price() {
        return this.getBalloonStatus(BalloonName.PRICE);
    }
    set price(value: boolean) {
        this.setBalloonStatus(BalloonName.PRICE, value);
    }
    get priceAlreadyShown() {
        return this._alreadyShown.map(item => item.balloonName).includes(BalloonName.PRICE);
    }

    get loginToSave() {
        return this.getBalloonStatus(BalloonName.LOGIN_TO_SAVE);
    }
    set loginToSave(value: boolean) {
        this.setBalloonStatus(BalloonName.LOGIN_TO_SAVE, value);
    }

    get expandArrow() {
        return this.getBalloonStatus(BalloonName.EXPAND_ARROW);
    }
    set expandArrow(value: boolean) {
        this.setBalloonStatus(BalloonName.EXPAND_ARROW, value);
    }

    get selectFirstPart() {
        return this.getBalloonStatus(BalloonName.SELECT_FIRST_PART);
    }
    set selectFirstPart(value: boolean) {
        this.setBalloonStatus(BalloonName.SELECT_FIRST_PART, value);
    }

    get selectPart() {
        return this.getBalloonStatus(BalloonName.SELECT_PART);
    }
    set selectPart(value: boolean) {
        this.setBalloonStatus(BalloonName.SELECT_PART, value);
    }

    get selectBench() {
        return this.getBalloonStatus(BalloonName.SELECT_BENCH);
    }
    set selectBench(value: boolean) {
        this.setBalloonStatus(BalloonName.SELECT_BENCH, value);
    }

    get selectRoof() {
        return this.getBalloonStatus(BalloonName.SELECT_ROOF);
    }
    set selectRoof(value: boolean) {
        this.setBalloonStatus(BalloonName.SELECT_ROOF, value);
    }

    get selectTable() {
        return this.getBalloonStatus(BalloonName.SELECT_TABLE);
    }
    set selectTable(value: boolean) {
        this.setBalloonStatus(BalloonName.SELECT_TABLE, value);
    }

    get fewParts() {
        return this.getBalloonStatus(BalloonName.FEW_PARTS);
    }
    set fewParts(value: boolean) {
        this.setBalloonStatus(BalloonName.FEW_PARTS, value);
    }
    get fewPartsAlreadyShown() {
        return this._alreadyShown.map(item => item.balloonName).includes(BalloonName.FEW_PARTS);
    }

    get benchParts() {
        return this.getBalloonStatus(BalloonName.BENCH_PARTS);
    }
    set benchParts(value: boolean) {
        this.setBalloonStatus(BalloonName.BENCH_PARTS, value);
    }
    get benchPartsAlreadyShown() {
        return this._alreadyShown.map(item => item.balloonName).includes(BalloonName.BENCH_PARTS);
    }


    get modifyPart() {
        return this.getBalloonStatus(BalloonName.MODIFY_PART);
    }
    set modifyPart(value: boolean) {
        this.setBalloonStatus(BalloonName.MODIFY_PART, value);
    }

    get measures() {
        return this.getBalloonStatus(BalloonName.MEASURES);
    }
    set measures(value: boolean) {
        this.setBalloonStatus(BalloonName.MEASURES, value);
    }

    get mouseInfo() {
        return this.getBalloonStatus(BalloonName.MOUSE_INFO);
    }
    set mouseInfo(value: boolean) {
        this.setBalloonStatus(BalloonName.MOUSE_INFO, value);
    }

    get altitude() {
        return this.getBalloonStatus(BalloonName.ALTITUDE);
    }
    set altitude(value: boolean) {
        this.setBalloonStatus(BalloonName.ALTITUDE, value);
    }

    get bridge() {
        return this.getBalloonStatus(BalloonName.BRIDGE);
    }
    set bridge(value: boolean) {
        if (value) {
            this.bridgeBeforeFloorSelection = false;
        }
        this.setBalloonStatus(BalloonName.BRIDGE, value);
    }

    get bridgeBeforeFloorSelection() {
        return this.getBalloonStatus(BalloonName.BRIDGE_BEFORE_FLOOR_SELECTION);
    }
    set bridgeBeforeFloorSelection(value: boolean) {
        this.setBalloonStatus(BalloonName.BRIDGE_BEFORE_FLOOR_SELECTION, value);
    }

    private closeBalloonsInSameGroup(balloonName: BalloonName) {
        let group: BalloonName[] = [];
        // Retrieve the group this balloonName belongs to.
        for (const groupName in balloonGroups) {
            if (balloonGroups[groupName].includes(balloonName)) {
                group = balloonGroups[groupName];
            }
        }
        // Remove all balloons in this group, with the exception of the provided balloonName.
        for (const balloonInGroup of group) {
            const index = this._show.map(item => item.balloonName).indexOf(balloonInGroup);
            if (index > -1 && balloonInGroup !== balloonName) {
                this._show.splice(index, 1);
            }
        }
    }

    private getBalloonStatus(balloonName: BalloonName) {
        return this.active
            && (this.guideMode || !guideBalloons.includes(balloonName))
            && this._show.map(item => item.balloonName).includes(balloonName);
    }

    private setBalloonStatus(balloonName: BalloonName, value: boolean) {
        if (!value) {
            this.removeBalloon(balloonName);
        }
        if (value) {
            this.showBalloon(balloonName);
            this._alreadyShown.push({balloonName: balloonName, displayElementChangeCount: this.elementChangeCount});
        }
    }

    private removeBalloon(balloonName: BalloonName) {
        const index = this._show.map(item => item.balloonName).indexOf(balloonName);
        if (index > -1) {
            this._show.splice(index, 1);
        }
    }

    private showBalloon(balloonName: BalloonName) {
        const index = this._show.map(item => item.balloonName).indexOf(balloonName);
        if (index === -1 && (!this._alreadyShown.map(item => item.balloonName).includes(balloonName) || repeatableBalloons.includes(balloonName))) {
            this._show.push({balloonName: balloonName, displayElementChangeCount: this.elementChangeCount});
            this.closeBalloonsInSameGroup(balloonName);
        }
    }
}
