import {
    AmbientLight, Box3, BoxGeometry,
    Color, Group,
    LinearToneMapping, Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshLambertMaterial, Object3D,
    PCFSoftShadowMap,
    PerspectiveCamera,
    PlaneGeometry,
    PointLight,
    Scene,
    Vector2, Vector3,
    WebGLRenderer,
} from 'three';
import {Instance3dData} from '@/model/3d/instance3dData';
import {Utils3d} from '@/model/3d/utils3d';
import {View3d} from '@/model/3d/view3d';
import {Pole} from '@/model/playground/pole';
import {View2d} from '@/model/2d/view2d';
import {OrbitControls2} from '@/model/3d/OrbitControls';

// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

export class World3d {

    get view(): View3d {
        return this._view;
    }
    private poleMap: Map<Pole, Instance3dData> = new Map<Pole, Instance3dData>();
    private _view: View3d = new View3d();
    private camera!: PerspectiveCamera;
    private renderer!: WebGLRenderer;
    private elements: Group[] = [];
    private pole!: Instance3dData;
    private scene = new Scene();
    private _stopAnimation = false;
    private floor?: Mesh;
    private debug = false;
    private testBoundingBox: Mesh[] = [];
    private objectNameFloor = 'floor';

    constructor(private utils3d: Utils3d, private id3dDiv: string) {
    }

    public addItem(newElement: Instance3dData, y: number, connector?: number, connectedToElement?: Instance3dData,
                   connectedToConnector?: number) {
        let x = 0;
        let z = 0;
        let rotation = 0;

        if (connectedToElement && connector !== undefined && connectedToConnector !== undefined) {
            const connectVectorAttach = connectedToElement.getConnectorPosition(connectedToConnector);
            const connectVectorAttachOther = connectedToElement.getConnectorPosition(
                (connectedToConnector + 1) % connectedToElement.getConnectorCount());
            const abused = new Vector2();

            let connectVectorMine = newElement.getConnectorPosition((connector + 1) % newElement.getConnectorCount());
            const connectVectorMineOther = newElement.getConnectorPosition(connector);
            rotation = abused.subVectors(connectVectorMine, connectVectorMineOther).angle()
                - abused.subVectors(connectVectorAttach, connectVectorAttachOther).angle();
            newElement.rotate(rotation);

            connectVectorMine = newElement.getConnectorPosition((connector + 1) % newElement.getConnectorCount());
            x += connectVectorAttach.x - connectVectorMine.x + connectedToElement.gltf.position.x;
            z += connectVectorAttach.y - connectVectorMine.y + connectedToElement.gltf.position.z;
        } else {
            newElement.rotate(rotation);
        }
        newElement.gltf.position.set(x, y, z);
        this.addToScene(newElement.gltf);
    }

    public async addPole(pole: Pole) {
        const model = this.pole.newInstance();
        const location = await pole.get3dLocation();
        model.gltf.position.set(location.x, pole.altitude, location.y);
        this.addToScene(model.gltf);
        this.poleMap.set(pole, model);
    }

    public deleteElement(model3d: Instance3dData) {
        const index = this.elements.indexOf(model3d.gltf);
        if (index !== -1) {
            this.scene.remove(model3d.gltf);
            this.elements.splice(index, 1);
        }
    }

    public async init(view: View2d, poleModelUrl: string) {
        this.pole = await this.utils3d.loadModel(poleModelUrl);

        const element3dDiv = document.getElementById(this.id3dDiv);
        const width = element3dDiv ? element3dDiv.clientWidth : 1280;
        const height = element3dDiv ? element3dDiv.clientHeight : 720;

        const fov = 75;
        const aspect = width / height;
        const near = 10.1;
        const far = 200000;

        this.scene.background = new Color('#FFFFFF');

        this.camera = new PerspectiveCamera(fov, aspect, near, far);
        // this.camera.setFocalLength(50);
        this.camera.position.z = 6000;
        this.camera.position.y = 3000;
        this.camera.rotateX(-Math.PI / 5);

        this.renderer = new WebGLRenderer({antialias: true});
        this.renderer.setSize(width, height);
        // renderer.gammaOutput = true;
        this.renderer.toneMapping = LinearToneMapping;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = PCFSoftShadowMap; // default THREE.PCFShadowMap

        if (element3dDiv) {
            element3dDiv.appendChild(this.renderer.domElement);
            const controls = new OrbitControls2(this.camera, element3dDiv);
            controls.minDistance = 3000;
            controls.maxDistance = 100000;
            if (!this.debug) {
                controls.maxPolarAngle = (85 / 360) * (Math.PI * 2);
            }
            controls.target.set(0, -0.2, -0.2);
            controls.update();
            this._view.controls = controls;
        }
        this._view.camera = this.camera;

        this._view.renderer = this.renderer;
        const light = new AmbientLight(0xffffff);
        light.intensity = 0.7;
        // light.castShadow = true;
        this.scene.add(light);

        const geometry = new PlaneGeometry(100000, 100000, 32, 32);
        const material = new MeshLambertMaterial({color: 0xffffff});
        this.floor = new Mesh(geometry, material);
        this.floor.rotateX(-Math.PI / 2);
        this.floor.receiveShadow = true;
        this.floor.castShadow = false;
        this.floor.position.set(0, -0.5, 0);
        this.floor.name = this.objectNameFloor;
        this.scene.add(this.floor);

        const pointLight = new PointLight(0xffffff, 0.8, 0);
        pointLight.position.set(0, 25000, 0);
        pointLight.castShadow = true;
        this.scene.add(pointLight);

        /*const shadowCaster = new Mesh(new SphereBufferGeometry( 500, 32, 32 ),
            new MeshLambertMaterial( { color: 0xff0000 } ));
        shadowCaster.position.set(pointLight.position.x, pointLight.position.y - 3000, pointLight.position.z);
        shadowCaster.castShadow = true;
        this.scene.add(shadowCaster);*/

        // const pointLightHelper = new PointLightHelper(pointLight, 100);
        // this.scene.add(pointLightHelper);

        // Set up shadow properties for the light
        pointLight.shadow.mapSize.width = 2048;  // default
        pointLight.shadow.mapSize.height = 2048; // default
        pointLight.shadow.camera.near = 0.5;       // default
        pointLight.shadow.camera.far = 25000000;      // default

        // const tree: Instance3dData = await this.utils3d.loadModel('lowpolytree.glb');
        // for (let i = 0; i < 200; ++i) {
        //     const newTree = tree.newInstance();
        //     const scale = 500 + Math.random() * 1000;
        //     const distance = 30000 + Math.random() * 20000;
        //     const direction = Math.random() * Math.PI * 2;
        //     newTree.gltf.scale.x = scale;
        //     newTree.gltf.scale.y = scale;
        //     newTree.gltf.scale.z = scale;
        //     newTree.gltf.position.z = distance * Math.cos(direction);
        //     newTree.gltf.position.x = distance * Math.sin(direction);
        //     newTree.gltf.position.y = scale * 2;
        //     this.scene.add(newTree.gltf);
        // }

        // const bb8: Instance3dData = await this.utils3d.loadModel('BB8.glb');
        // const newbb8 = bb8.newInstance();
        // newbb8.gltf.scale.x = 200;
        // newbb8.gltf.scale.y = 200;
        // newbb8.gltf.scale.z = 200;
        // newbb8.gltf.castShadow = true;
        // this.scene.add(newbb8.gltf);

        const animate = () => {
            if (this._stopAnimation) {
                return;
            }
            requestAnimationFrame(animate);
            this.renderer.render(this.scene, this.camera);
        };

        animate();
    }

    public stopAnimation() {
        this._stopAnimation = true;
    }

    public async takeScreenshot(width: number, height: number, side: number, angle: number,
                                boundingBox: Vector2[] | null, playgroundHeight: number,
                                safetyHeight: number, actualHeight: number): Promise<string> {
        this._view.saveState();
        const oldFloorMaterial = this.floor!.material;
        const oldCameraAspect = this.camera.aspect;
        const oldFar = this.camera.far;
        this.camera.far = 20000000;
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        const oldSize = new Vector2();
        const oldFocalLength = this.camera.getFocalLength();
        this.renderer.getSize(oldSize);
        this.renderer.setSize(width, height);
        const sizesOfPlayground = new Vector2();
        const oldSceneBackgroundColor = this.scene.background;
        const heightView = side === 0 && angle === 1;
        const whiteSpace = 100;

        // Dont change perspective if we want to use the current perspective
        if (side !== -1) {
            // we only want to change this if we need 'clean' screenshots,
            // not the downloadable (current perspective) one.
            this.floor!.material = new MeshBasicMaterial({color: 0xffffff});
            this.scene.background = new Color(0xffffff);
            this.camera.setFocalLength(heightView ? 500 : 50);

            this.lookFromDirection(boundingBox, actualHeight, side, angle, sizesOfPlayground, height, width,
                heightView ? whiteSpace : undefined);
        }
        this.renderer.render(this.scene, this.camera);
        const result = this.renderer.domElement.toDataURL('image/png');

        if (side !== -1) {
            // Set everything back if we didnt use the current perspective
            this.camera.setFocalLength(oldFocalLength);
            this._view.update();
            this.floor!.material = oldFloorMaterial;
            this.scene.background = oldSceneBackgroundColor;
        }
        this._view.reset();
        // We always change the aspect, so we need to always change it back
        this.camera.aspect = oldCameraAspect;
        this.camera.far = oldFar;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(oldSize.width, oldSize.height);
        this.renderer.render(this.scene, this.camera);

        if (heightView) {
            const canvas = document.createElement('canvas') as HTMLCanvasElement;
            canvas.width = width;
            canvas.height = height;
            const img = new Image();

            img.src = result;
            await new Promise((resolve) => {
                img.onload = () => {
                    resolve(true);
                };
            });

            const ctx = canvas.getContext('2d');
            if (ctx) {
                const worldToPxRatio = sizesOfPlayground.y / height;
                const padPx = whiteSpace / 3;
                const playgroundWidthPx = sizesOfPlayground.x / worldToPxRatio - padPx;
                const groundLevel = height / 2 + (1200 / worldToPxRatio);

                ctx.moveTo(0, groundLevel);
                ctx.lineTo(width, groundLevel);
                ctx.stroke();

                let playgroundHeightPx = playgroundHeight / (sizesOfPlayground.y / 2) * height / 2;
                ctx.drawImage(img, 0, 0);
                ctx.moveTo(padPx / 2, groundLevel - playgroundHeightPx);
                ctx.lineTo(padPx / 2, groundLevel);
                ctx.stroke();

                ctx.moveTo(0, groundLevel - playgroundHeightPx);
                ctx.lineTo(width - (padPx / 2 + 50), groundLevel - playgroundHeightPx);
                ctx.stroke();

                ctx.save();
                ctx.font = '14px arial';
                ctx.textAlign = 'center';
                ctx.translate(padPx / 2, groundLevel - playgroundHeightPx / 2);
                ctx.rotate(-Math.PI / 2);
                ctx.fillText(`${Math.ceil(playgroundHeight / 10)} cm`, 0, -3);
                ctx.restore();

                playgroundHeightPx = safetyHeight / (sizesOfPlayground.y / 2) * height / 2;
                ctx.moveTo(playgroundWidthPx + padPx / 2, groundLevel - playgroundHeightPx);
                ctx.lineTo(playgroundWidthPx + padPx / 2, groundLevel);
                ctx.stroke();

                ctx.moveTo(padPx / 2 + 50, groundLevel - playgroundHeightPx);
                ctx.lineTo(width, groundLevel - playgroundHeightPx);
                ctx.stroke();

                ctx.save();
                ctx.font = '14px arial';
                ctx.textAlign = 'center';
                ctx.translate(playgroundWidthPx + padPx / 2, groundLevel - playgroundHeightPx / 2);
                ctx.rotate(-Math.PI / 2);
                ctx.fillText(`${Math.ceil(safetyHeight / 10)} cm`, 0, -3);
                ctx.restore();
            }
            return canvas.toDataURL('image/png');
        }

        return result;
    }

    public lookFromDirection(boundingBox: Vector2[] | null, actualHeight: number, side: number,
                             angle: number, sizesOfPlayground?: Vector2, height?: number, width?: number,
                             widthWhiteSpace?: number) {
        if (boundingBox) {
            const size = new Vector2();
            this.renderer.getSize(size);
            if (widthWhiteSpace) {
                size.width -= widthWhiteSpace;
            }
            const lengthVector = new Vector2(boundingBox[0].x + (boundingBox[1].x - boundingBox[0].x) / 2,
                boundingBox[0].y + (boundingBox[1].y - boundingBox[0].y) / 2)
                .rotateAround(new Vector2(), -(side / 180) * Math.PI);
            const newTarget = this._view.target.clone();
            newTarget.x = lengthVector.x;
            newTarget.z = lengthVector.y;
            newTarget.y = 1200;
            this._view.target = newTarget;


            actualHeight = Math.max(2400, actualHeight);
            let playgroundWidth = boundingBox[1].x - boundingBox[0].x;
            if (playgroundWidth < 3000) {
                playgroundWidth = 3000;
            }

            let playgroundDepth = boundingBox[1].y - boundingBox[0].y;

            if (this.debug) {
                for (const removeBox of this.testBoundingBox) {
                    this.scene.remove(removeBox);
                }
                if (true) { //eslint-disable-line
                    const testBlock = new BoxGeometry(playgroundWidth, actualHeight, playgroundDepth, 10, 10, 10);
                    const testMaterial = new MeshBasicMaterial({color: 0xfffff, wireframe: true});
                    const box = new Mesh(testBlock, testMaterial);
                    box.position.x = lengthVector.x;
                    box.position.z = lengthVector.y;
                    box.position.y = actualHeight / 2;
                    box.rotateY((side / 180) * Math.PI);
                    this.scene.add(box);
                    this.testBoundingBox.push(box);
                }
            }
            const dh = this.getDepthsAndHeights(playgroundWidth / 2, actualHeight - 1200, playgroundDepth / 2,
                angle);
            playgroundDepth = Math.abs(dh.depth);
            actualHeight = Math.abs(dh.height);
            if (this.debug) {
                if (!true) { //eslint-disable-line
                    const testBlock = new BoxGeometry(playgroundWidth, actualHeight, playgroundDepth, 10, 10, 10);
                    const testMaterial = new MeshBasicMaterial({color: 0xff0000, wireframe: true});
                    const box = new Mesh(testBlock, testMaterial);
                    box.position.x = lengthVector.x;
                    box.position.z = lengthVector.y;
                    box.position.y = 1200;
                    box.rotateY((side / 180) * Math.PI);
                    box.rotateX((-angle / 180) * Math.PI);
                    this.scene.add(box);
                    this.testBoundingBox.push(box);
                }

                if (!true) { //eslint-disable-line
                    const testBlock = new BoxGeometry(playgroundWidth, actualHeight, playgroundDepth / 2, 10, 10, 10);
                    const testMaterial = new MeshBasicMaterial({color: 0x0ff000, wireframe: true});
                    const box = new Mesh(testBlock, testMaterial);
                    box.position.x = lengthVector.x;
                    box.position.z = lengthVector.y;
                    box.position.y = 1200;
                    box.rotateY((side / 180) * Math.PI);
                    box.rotateX((-angle / 180) * Math.PI);
                    this.scene.add(box);
                    this.testBoundingBox.push(box);
                }
            }

            if ((size.width / size.height)  < (playgroundWidth / actualHeight)) {
                actualHeight = playgroundWidth / (size.width / size.height);
            }

            if (this.debug) {
                if (!true) { //eslint-disable-line
                    const testBlock = new BoxGeometry(playgroundWidth, actualHeight, playgroundDepth, 10, 10, 10);
                    const testMaterial = new MeshBasicMaterial({color: 0x0000ff, wireframe: true});
                    const box = new Mesh(testBlock, testMaterial);
                    box.position.x = lengthVector.x;
                    box.position.z = lengthVector.y;
                    box.position.y = 1200;
                    box.rotateY((side / 180) * Math.PI);
                    box.rotateX((-angle / 180) * Math.PI);
                    this.scene.add(box);
                    this.testBoundingBox.push(box);
                }
            }

            const distance = (actualHeight / 2) / Math.tan(((this.camera.fov / 2) / 180) * Math.PI)
                + playgroundDepth / 2;
                // + ((boundingBox[1].y - boundingBox[0].y) / 2);
            this._view.magnification = distance / this._view.startDistance;
            if (sizesOfPlayground && height && width) {
                sizesOfPlayground.y = 2.0 * distance * Math.tan(this.camera.fov * 0.5 * (Math.PI / 180));
                sizesOfPlayground.x = sizesOfPlayground.y * (width / height);
            }
        } else {
            this._view.magnification = 100;
        }
        this._view.absolutePosition(side, angle);
        return sizesOfPlayground;
    }

    public cleanup() {
        const i = 0;
        for (const element of this.elements) {
            this.scene.remove(element);
        }
        this.elements.splice(0);
    }

    public removePole(pole: Pole) {
        const instance = this.poleMap.get(pole);
        if (instance) {
            this.deleteElement(instance);
        }
    }

    public updatePole(pole: Pole) {
        const instance = this.poleMap.get(pole);
        if (instance) {
            instance.setAltitude(pole.altitude);
        }
    }

    private getDepthsAndHeights(x: number, y: number, z: number, angle: number): {depth: number, height: number} {
        let height = 0;
        let depth = 0;
        for (const vec of [new Vector3(x, y, z), new Vector3(x, -y, z), new Vector3(x, y, -z),
                new Vector3(x, -y, -z)]) {
            const angledVector = vec.applyAxisAngle(new Vector3(1, 0, 0), (-angle / 180) * Math.PI);
            depth = Math.max(depth, Math.abs(angledVector.z) * 2);
            height = Math.max(height, Math.abs(angledVector.y) * 2);
        }
        return {depth, height};
    }

    get exportGltf(): Scene {
        const sceneClone = this.cloneSceneWithoutPolesAndFloor;
        this.poleMap.forEach((data3dPole: Instance3dData, key: Pole) => {
            const pole3dClone = data3dPole.gltf.clone();
            const poleBox = new Box3().setFromObject(pole3dClone);
            const poleHeightAboveGround = poleBox.max.y;
            const poleTotalLength = poleHeightAboveGround - poleBox.min.y;
            const multiplier = poleHeightAboveGround / poleTotalLength;

            const matrixPoleLengthMultiplier = new Matrix4();
            matrixPoleLengthMultiplier.makeScale(1, multiplier, 1);
            pole3dClone.applyMatrix4(matrixPoleLengthMultiplier);
            pole3dClone.position.y = poleHeightAboveGround;
            sceneClone.add(pole3dClone);
        });
        return sceneClone;
    }

    get cloneSceneWithoutPolesAndFloor() {
        const objectNamePole = 'pole';
        this.poleMap.forEach((value: Instance3dData, key: Pole) => {
            value.gltf.name = objectNamePole;
        });
        const sceneClone = this.scene.clone(true);
        const floor = sceneClone.getObjectByName(this.objectNameFloor);
        if (floor) {
            sceneClone.remove(floor);
        }
        const poles = sceneClone.getObjectsByProperty('name', objectNamePole);
        poles.forEach(poleObject => {
            sceneClone.remove(poleObject)
        });

        const removeConnectors = (object: Object3D) => {
            const childrenToRemove = [];
            for (const x of (object.children ?? [])) {
                if (x.name?.startsWith('connector')) {
                    childrenToRemove.push(x);
                }
                removeConnectors(x);
            }
            for (const x of childrenToRemove) {
                object.remove(x);
            }
        };
        removeConnectors(sceneClone);
        return sceneClone;
    }

    private addToScene(scene: Group) {
        this.scene.add(scene);
        this.elements.push(scene);
    }
}
