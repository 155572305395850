import {MinimalBoundingBox} from '@/model/2d/minimalBoundingBox';
import {VectorMath} from '@/model/2d/vectorMath';
import {Vector2} from 'three';
import {IDampeningFloor} from '@/model/DampeningFloor';

export class FallSurface {
    private unalignedBox: Vector2[] = [];
    private box: Vector2[] = [];
    private corner = 0;
    private _svg = '';
    private _area = 0;
    private _resolutionWidth = 1;
    private _resolutionHeight = 1;
    private _dampingFloorColor = 'eeeeee';
    private _rotation = 0;

    constructor(
        private minimalBoundingBox: MinimalBoundingBox,
        dampeningFloor: IDampeningFloor | null = null,
        private rotation: number = 0,
    ) {
        if (dampeningFloor) {
            this._resolutionWidth = dampeningFloor.tileWidth || 1;
            this._resolutionHeight = dampeningFloor.tileHeight || 1;
            this._dampingFloorColor = dampeningFloor.color;
        }
        this._rotation = rotation;
    }

    public calculate(rotation?: number, optimize = true) {
        this.minimalBoundingBox.calculate(rotation, optimize);
        const box = this.minimalBoundingBox.minimalBoundingBox;
        if (!box.length) {
            return;
        }
        this.fillAlignBox(box);
        this._svg += '<polygon points="' + this.box.map((point) => '' + point.x + ',' + point.y).join(',')
            + ' ' + '" id="svg-damping-floor-surface" style="fill:#' + this._dampingFloorColor + '"/>';

        this._svg += '<polygon points="' + box.map((point) => '' + point.x + ',' + point.y).join(',')
            + ' ' + '" id="svg-fall-surface" />';
        this._svg += this.getMeasurementsSvg(box, rotation);

        // Show box-numbers in corners of the bounding box
        // for (let i = 0; i < this.box.length; ++i) {
        //    this._svg += '<text font-size="300" x="' + this.box[i].x + '" y="' + this.box[i].y + '">' + i + '</text>';
        // }
    }

    // public offset(input: Vector2): any {
    //     let corner = VectorMath.corner(this.box[0], this.box[1]);
    //     let resultBox = this.box.map((point) => point.clone().rotateAround(input, - corner));
    //     if (resultBox[0].x > resultBox[1].x) {
    //         resultBox = resultBox.map((point) => point.rotateAround(input, - Math.PI));
    //         corner += Math.PI;
    //     }
    //     const area = Math.round((resultBox[0].y - resultBox[2].y) * (resultBox[2].x - resultBox[0].x));
    //
    //     resultBox[0].x -= input.x;
    //     resultBox[0].y -= input.y;
    //
    //     let times = Math.floor(resultBox[0].x / this.resolution);
    //     resultBox[0].x -= times * this.resolution;
    //     times = Math.floor(resultBox[0].y / this.resolution);
    //     resultBox[0].y -= times * this.resolution;
    //     return {x: Math.round(resultBox[0].x), y: Math.round(resultBox[0].y), corner, area};
    // }

    get area(): number {
        return this._area;
    }

    get svg(): string {
        return this._svg;
    }

    private fillAlignBox(box: Vector2[]) {
        this.corner = VectorMath.corner(box[0], box[1]);
        this.unalignedBox = box.map((point) => point.clone().rotateAround(box[0], - this.corner));
        if (this.unalignedBox[0].x > this.unalignedBox[1].x) {
            this.unalignedBox = this.unalignedBox.map((point) => point.rotateAround(box[0], - Math.PI));
            this.corner += Math.PI;
        }
        const dxOld = this.unalignedBox[2].x - this.unalignedBox[0].x;
        const dyOld = this.unalignedBox[0].y - this.unalignedBox[2].y;
        const dx = ((this._resolutionWidth - (dxOld % this._resolutionWidth)) % this._resolutionWidth) / 2;
        const dy = ((this._resolutionHeight - (dyOld % this._resolutionHeight)) % this._resolutionHeight) / 2;

        this._area = Math.round((dx * 2 + dxOld) * (dy * 2 + dyOld));

        this.unalignedBox[0].x -= dx;
        this.unalignedBox[0].y += dy;
        this.unalignedBox[1].x += dx;
        this.unalignedBox[1].y += dy;
        this.unalignedBox[2].x += dx;
        this.unalignedBox[2].y -= dy;
        this.unalignedBox[3].x -= dx;
        this.unalignedBox[3].y -= dy;

        this.box = this.unalignedBox.map((point) => point.clone().rotateAround(box[0], this.corner));
    }

    private getMeasurementsSvg(box: Vector2[], playgroundRotation: any): string {
        const offset = 200;

        let rotationDiff = 0;
        if (playgroundRotation) {
            rotationDiff = 90 - playgroundRotation;
        }
        const rotation = (this._rotation - rotationDiff) % 360;
        let result = '';
        let points: Vector2[] = [new Vector2(this.unalignedBox[0].x, this.unalignedBox[0].y + offset),
            new Vector2(this.unalignedBox[1].x, this.unalignedBox[1].y + offset),
            new Vector2(
                this.unalignedBox[0].x - (this.unalignedBox[0].x - this.unalignedBox[1].x) / 2,
                this.unalignedBox[0].y + 2.5 * offset,
            ),

            new Vector2(this.unalignedBox[1].x + offset, this.unalignedBox[1].y),
            new Vector2(this.unalignedBox[2].x + offset, this.unalignedBox[2].y),
            new Vector2(
                this.unalignedBox[2].x + 2.5 * offset,
                this.unalignedBox[1].y + ((this.unalignedBox[2].y - this.unalignedBox[1].y) / 2),
            ),
        ];
        points = points.map((point) => point.clone().rotateAround(box[0], this.corner));

        result += '<polygon points="' + points[0].x + ',' + points[0].y + ','  + points[1].x + ',' + points[1].y
            + '" class="svg-measurements" />';

        result += '<polygon  points="' + points[3].x + ',' + points[3].y + ','  + points[4].x + ',' + points[4].y
            + '" class="svg-measurements" />';

        // Align the text with the measurement-line
        let textAngle = Math.atan2(points[0].y - points[1].y, points[0].x - points[1].x) * 180 / Math.PI;
        // Mirror text when the text would be upside down due to rotation
        if ((rotation > -270 && rotation < 0) || rotation > 90) {
            textAngle += 180;
        }

        result += '<text text-anchor="middle" class="svg-measurements" font-size="300" ' +
            'x="' + points[2].x + '" y="' + points[2].y + '"' +
            ' transform="rotate(' + textAngle + ' ' + points[2].x + ' ' + points[2].y + ')"' +
            '>'
            + Math.round((this.unalignedBox[1].x - this.unalignedBox[0].x) / 10) + 'cm</text>';

        // Align the text with the measurement-line
        let text2Angle = Math.atan2(points[3].y - points[4].y, points[3].x - points[4].x) * 180 / Math.PI;
        // Mirror text when the text would be upside down due to rotation
        if (rotation > -180 && (rotation < 90 || rotation > 180)) {
            text2Angle += 180;
        }
        result += '<text text-anchor="middle" class="svg-measurements" font-size="300" ' +
            'x="' + points[5].x + '" y="' + points[5].y + '"' +
            ' transform="rotate(' + text2Angle + ' ' + points[5].x + ' ' + points[5].y + ')"' +
            '>'
            + Math.round((this.unalignedBox[1].y - this.unalignedBox[2].y) / 10) + 'cm</text>';

        return result;
    }
}
