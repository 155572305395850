import {IRole} from '@/model/Role';
import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import {RootState} from '@/store';
import {IUser} from '@/model/User';
import {http, url} from '@/utils/http';

export interface AuthenticationState {
    authenticated: boolean;
}

export function hasRole(user: IUser, name: string): boolean {
    return user != null && user.roles != null && user.roles.find((t: IRole) => t.name === name) != null;
}

@Module
export default class AuthenticationModule extends VuexModule<AuthenticationState, RootState> {
    public authenticated = false;
    public user: IUser = {
        email: '',
        roles: [],
        firstName: '',
        lastName: '',
        address: {
            country: {id: 1},
            zipCode: '',
            houseNumber: '',
            street: '',
            city: '',
        },
        billingDifferent: false,
        billingAddress: null,
        phoneNumber: '',
    };

    @Mutation
    public setAuthenticated(value: boolean) {
        this.authenticated = value;
    }

    @Mutation
    public setUser(user: IUser) {
        if (user) {
            user.billingDifferent = !!user.billingAddress;
        }
        this.user = user;
    }

    @MutationAction({mutate: ['authenticated', 'user']})
    public async logout() {
        await http.get(url('logout'));
        return {
            authenticated: false, user: {
                email: '',
                roles: [],
                firstName: '',
                lastName: '',
                address: {
                    country: {id: 1},
                    zipCode: '',
                    houseNumber: '',
                    street: '',
                    city: '',
                },
                billingDifferent: false,
                billingAddress: null,
                phoneNumber: '',
            },
        };
    }
}


