import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IPagination} from '@/utils/api-tools/pagination';
import {ISearchCriterion} from '@/utils/api-tools/search-criteria';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IColorTheme} from '@/model/ColorTheme';

export class ColorThemeApi {
    public async getColorThemes(pagination: IPagination, search: ISearchCriterion[]):
        Promise<IWrapperResponse<IPaginationResponse<IColorTheme>>> {
        return new GenericApi<IColorTheme>('/api/v1/color-theme').list(pagination, search);
    }
}
