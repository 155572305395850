
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Modal from '@/components/Modal.vue';
    import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
    import PlaygroundCard from '@/components/PlaygroundCard.vue';
    import InputField from '@/components/InputField.vue';
    import {Playground} from '@/model/playground/playground';
    import {IPartSpec} from '@/model/PartSpec';
    import {IPart} from '@/model/Part';
    import {IDampeningFloor} from '@/model/DampeningFloor';
    import {Vector2} from 'three';
    import {State} from 'vuex-class';
    import {IPlayground, IPoleConfig} from '@/model/Playground';
    import {PlaygroundPriceCalculator} from '@/model/playground/playgroundPriceCalculator';
    import {Step} from '@/model/playground/step';
    import {PartCategoryType} from '@/model/PartCategory';

    @Component({
        components: {InputField, PlaygroundCard, CloseCircleIcon, Modal},
    })
    export default class PlaygroundPriceTable extends Vue {
        @Prop()
        private playground!: Playground | null;
        @Prop({default: null})
        private playgroundInfo!: IPlayground | null;
        @State((state) => state.playground.poleConfig)
        private poleConfig!: IPoleConfig | null;
        @State((state) => state.part.parts.content)
        private parts!: IPart[];
        @State((state) => state.playground.certificationCosts)
        private certificationCosts!: number;
        private playgroundPriceCalculator: PlaygroundPriceCalculator | null = null;

        private async created() {
            // await this.$store.dispatch('fetchPoleConfig');
            if (this.poleConfig) {
                this.playgroundPriceCalculator = new PlaygroundPriceCalculator(
                    this.poleConfig,
                    this.parts,
                    this.certificationCosts,
                );
            }
        }

        get totalPrice(): number {
            let constructionPrice = this.playgroundInfo ? this.playgroundInfo.constructionPrice : 0;
            constructionPrice = constructionPrice ? constructionPrice : 0;
            return this.playgroundPriceCalculator && this.playground
                ? this.playgroundPriceCalculator.getPrice(this.playground) + constructionPrice
                : 0;
        }

        get poles(): Array<{count: number, size: number}> {
            if (!this.playground) {
                return [];
            }
            return this.playground.export().poles;
        }

        get steps(): Step[] {
            if (!this.playground) {
                return [];
            }
            return this.playground.export().steps;
        }

        get dampeningFloor(): IDampeningFloor | null {
            return this.playground ? this.playground.dampeningFloor : null;
        }

        private getStepPrice(step: any) {
            return this.playgroundPriceCalculator ? this.playgroundPriceCalculator
                .getStepPrice(this.getStepHeight(step), step.partSpecId) : null;
        }

        private getStepHeight(step: any) {
            const findStepHeight = (stepId: number) => {
                let partSpecs: any = this.parts
                    .filter((part: IPart) => part.category.type === PartCategoryType.STEP)
                    .map((part: IPart) => part.partSpecs)
                ;
                partSpecs = [].concat.apply([], partSpecs);
                const partSpec = partSpecs.find((ps: IPartSpec) => {
                    return ps.id === stepId;
                });
                if (partSpec && partSpec.heights && partSpec.heights.length) {
                    return partSpec.heights[0].height / 10;
                }
                return 0;
            };
            return step.height != null ? step.height / 10 : findStepHeight(step.partSpecId);
        }

        private getPolePrice(poleSize: number) {
            return this.playgroundPriceCalculator ? this.playgroundPriceCalculator.getPolePrice(poleSize) : null;
        }

        private getTotalPolesPrice(): number {
            return this.playgroundPriceCalculator && this.playground
                ? this.playgroundPriceCalculator.getTotalPolesPrice(this.playground)
                : 0;
        }

        private getNumberForPartSpecs(partSpec: IPartSpec): number {
            return this.groupedPartSpecs.get(partSpec).number || 0;
        }

        private getPartForPartSpecs(partSpec: IPartSpec): IPart {
            return this.groupedPartSpecs.get(partSpec).part;
        }

        private getHeightsForPartSpecs(partSpec: IPartSpec): number[] {
            return this.groupedPartSpecs.get(partSpec).heights || [];
        }

        get groupedPartSpecs(): Map<IPartSpec, any> {
            const groupedPartSpecs: Map<IPartSpec, any> = new Map<IPartSpec, any>();
            if (!this.playground) {
                return groupedPartSpecs;
            }
            for (const element of this.playground.playgroundElements.values()) {
                let partSpecInfo = groupedPartSpecs.get(element.partSpec);
                if (partSpecInfo) {
                    partSpecInfo.number++;
                    partSpecInfo.push = element.heightOffset;
                    if (!partSpecInfo.heights.includes(element.heightOffset)) {
                        partSpecInfo.heights.push(element.heightOffset);
                    }
                } else {
                    partSpecInfo = {
                        number: 1,
                        part: element.playgroundPart.part,
                        heights: [element.heightOffset],
                    };
                }
                groupedPartSpecs.set(element.partSpec, partSpecInfo);
            }
            return groupedPartSpecs;
        }

        get fallSurfaceWidth() {
            if (!this.fallSurfaceVectors) {
                return 0;
            }
            return this.fallSurfaceVectors[0].distanceTo(this.fallSurfaceVectors[1]);
        }

        get fallSurfaceHeight() {
            if (!this.fallSurfaceVectors) {
                return 0;
            }
            return this.fallSurfaceVectors[0].distanceTo(this.fallSurfaceVectors[3]);
        }

        get fallSurfaceSquareMeter() {
            if (!this.dampeningFloor || !this.tilesNeeded) {
                return 0;
            }
            return this.tilesNeeded * this.dampeningFloor.tileWidth * this.dampeningFloor.tileHeight / 1000000;
        }

        get tilesNeeded() {
            if (!this.dampeningFloor || !this.dampeningFloor.tileWidth || !this.dampeningFloor.tileHeight) {
                return null;
            }
            const tilesPerWidth = Math.ceil(this.fallSurfaceWidth / this.dampeningFloor.tileWidth);
            const tilesPerHeight = Math.ceil(this.fallSurfaceHeight / this.dampeningFloor.tileHeight);
            return tilesPerWidth * tilesPerHeight;
        }

        get fallSurfaceVectors(): null | Vector2[] {
            if (!this.playground) {
                return null;
            }
            return this.playground.getFallSurfaceBoundingBoxVectors();
        }
    }
