import {Idable} from '@/model/Idable';

export enum PartCategoryType {
    FLOOR = 1, NORMAL = 2, BOUND = 3, STEP = 4, ROOF = 5, BENCH = 6, ENTRANCE = 7, TABLE = 8, SLIDE = 9,
}

export interface IPartCategory extends Idable {
    id: number;
    name: string;
    description: string;
    icon: string;
    type: PartCategoryType;
}
