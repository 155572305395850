import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IPagination} from '@/utils/api-tools/pagination';
import {ISearchCriterion} from '@/utils/api-tools/search-criteria';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IColor} from '@/model/Color';

export class ColorApi {
    public async getColors(pagination: IPagination, search: ISearchCriterion[]):
        Promise<IWrapperResponse<IPaginationResponse<IColor>>> {
        return new GenericApi<IColor>('/api/v1/color').list(pagination, search);
    }
}
