import {PlaygroundElement} from '@/model/playground/playgroundElement';
import {Playground} from '@/model/playground/playground';
import {Instance3dData} from '@/model/3d/instance3dData';
import {UtilsPlayground} from '@/model/playground/utilsPlayground';

export class Step {
    private _dt = NaN;
    private instance: Instance3dData | null = null;
    private _stepId: number | null = null;

    constructor(private element1: PlaygroundElement, private connector1: number, private element2: PlaygroundElement,
                private connector2: number, private playground: Playground, private utilsPlayground: UtilsPlayground) {
    }

    public delete() {
        if (this.instance) {
            this.playground.world3d.deleteElement(this.instance);
        }
    }

    public async update() {
        const dt = (this.element1.model2d.getConnectorAltitude(this.connector1) + this.element1.heightOffset)
            - (this.element2.model2d.getConnectorAltitude(this.connector2) + this.element2.heightOffset);
        if (this._dt !== dt) {
            this._stepId = null;
            this._dt = dt;
            if (this.instance !== null) {
                this.playground.world3d.deleteElement(this.instance);
            }
            if (dt !== 0) {
                const stepGroups = this.utilsPlayground.steps.get(Math.abs(dt));
                if (stepGroups) {
                    const newElement: PlaygroundElement | undefined = stepGroups
                        .get(this.element1.model2d.getConnectorGroups()[this.connector1].sizeGroup);
                    if (newElement) {
                        if (newElement.partSpec.id !== undefined) {
                            this._stepId = newElement.partSpec.id;
                        }
                        this.instance = (await newElement.getModel3d()).newInstance();
                        const element = dt < 0 ? this.element1 : this.element2;
                        const connector = dt < 0 ? this.connector1 : this.connector2;
                        this.playground.world3d.addItem(this.instance,
                            element.model2d.getConnectorAltitude(connector) + element.heightOffset, 0,
                            await element.getModel3d(), connector);
                    }
                }
            }
        }
    }

    public matches(element: PlaygroundElement): boolean {
        return this.element1 === element || this.element2 === element;
    }

    public exists(): boolean {
        return this.instance !== null;
    }

    get dt(): number {
        return this._dt;
    }

    get stepId(): number | null {
        return this._stepId;
    }
}
