import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "user-edit" */ './views/Dashboard.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/login',
            name: 'login',
            // route level codeName-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
        },
        {
            path: '/request-offer',
            name: 'request_offer',
            component: () => import(/* webpackChunkName: "offer" */ './views/RequestOffer.vue'),
        },
        {
            path: '/request-password',
            name: 'request_password',
            component: () => import(/* webpackChunkName: "register" */ './views/RequestPassword.vue'),
        },
        {
            path: '/request-password/:token',
            name: 'request_password_step_2',
            component: () => import(/* webpackChunkName: "register" */ './views/RequestPasswordStep2.vue'),
        },
        {
            path: '/ground-selection',
            name: 'configurator_ground_selection',
            component: () => import(/* webpackChunkName: "builder" */ './views/GroundSelection.vue'),
        },
        {
            path: '/builder',
            name: 'builder',
            component: () => import(/* webpackChunkName: "builder" */ './views/Builder.vue'),
        },
        {
            path: '/user-edit',
            name: 'user_edit',
            component: () => import(/* webpackChunkName: "user-edit" */ './views/UserEdit.vue'),
            meta: { requiresAuth: true },
        },
        {
            path: '/playground-order/:playground_id',
            name: 'playground_order',
            component: () => import(/* webpackChunkName: "playgrounds" */ './views/PlaygroundOrder.vue'),
        },
        {
            path: '/test',
            name: 'test',
            component: () => import(/* webpackChunkName: "about" */ './views/Test.vue'),
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import(/* webpackChunkName: "logout" */ './views/Logout.vue'),
            meta: { requiresAuth: false },
        },
        {
            path: '*',
            name: 'not_found',
            component: () => import(/* webpackChunkName: "not-found" */ './views/NotFound.vue'),
            meta: { requiresAuth: false },
        },
    ],
});
