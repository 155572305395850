export class Mutex {
    private queue: Array<(resolve: void) => void> = [];
    private pending = false;

    public acquire(): Promise<void> {
        return new Promise<void>((resolve) => this.addResolver(resolve));
    }

    public release() {
        if (this.queue.length > 0) {
            this.pending = true;
            this.queue.shift()!();
        } else {
            this.pending = false;
        }
    }

    private addResolver(executor: (resolve: void) => void) {
        this.queue.push(executor);
        if (!this.pending) {
            this.release();
        }
    }
}
