
    import {Component, Vue} from 'vue-property-decorator';
    import Modal from '@/components/Modal.vue';
    import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
    import PlaygroundCard from '@/components/PlaygroundCard.vue';
    import InputField from '@/components/InputField.vue';
    import PhoneIcon from 'vue-material-design-icons/Phone.vue';
    import SendIcon from 'vue-material-design-icons/Send.vue';
    import {ContactApi} from '@/api/contact';
    import {IContact} from '@/model/Contact';

    @Component({
        components: {
            InputField,
            PlaygroundCard,
            CloseCircleIcon,
            Modal,
            PhoneIcon,
            SendIcon,
        },
    })
    export default class ContactModal extends Vue {
        private name: string|null = null;
        private email: string|null = null;
        private question: string|null = null;
        private warning: string | null = null;
        private contactApi: ContactApi = new ContactApi();
        private sending = false;
        private messageSent = false;
        private errors: any = {
            name: null,
            email: null,
            question: null,
        } as any;

        private async sendMessage() {
            let hasError = false;
            if (!this.name) {
                this.errors.name = 'Het naam veld mag niet leeg zijn.';
                hasError = true;
            }
            if (!this.email) {
                this.errors.email = 'E-mailadres mag niet leeg zijn.';
                hasError = true;
            } else if (!/\S+@\S+\.\S{2,}/.test(this.email)) {
                this.errors.email = 'Ongeldig e-mailadres';
                hasError = true;
            }
            if (!this.question) {
                this.errors.question = 'Vraag of opmerking veld mag niet leeg zijn.';
                hasError = true;
            }
            if (hasError) {
                this.warning = 'Eén of meerdere velden zijn niet correct ingevuld.';
                return;
            }
            try {
                this.sending = true;
                const response = await this.contactApi.sendMessage({
                    name: this.name,
                    email: this.email,
                    message: this.question,
                } as IContact);
                if (response.success) {
                    this.reload();
                    this.messageSent = true;
                }
            } catch (e) {
                this.warning = 'Er is een fout opgetreden tijdens het verzenden van uw vraag of opmerking.';
            }
            this.sending = false;
        }

        private reload() {
            this.name = null;
            this.email = null;
            this.question = null;
            this.warning = null;
            this.messageSent = false;
        }
    }
