import {PlaygroundElement} from '@/model/playground/playgroundElement';

export class PoleConnector {
    private _altitude!: number;

    constructor(private _playgroundElement: PlaygroundElement, private _connector: number) {
        this.update();
    }

    public update() {
        this._altitude = this._playgroundElement.connectorMountingAltitude(this._connector);
    }

    get playgroundElement(): PlaygroundElement {
        return this._playgroundElement;
    }

    get connector(): number {
        return this._connector;
    }

    get altitude(): number {
        return this._altitude;
    }
}
