import {Vector2} from 'three';
import {MapEntry} from '@/model/util/mapEntry';

export class LocationMap<T> {
    private entries: Array<MapEntry<T>> = [];

    public values(): T[] {
        const result: T[] = [];
        for (const entry of this.entries) {
            result.push(entry.value);
        }
        return result;
}


    public add(location: Vector2, value: T) {
        this.entries.push(new MapEntry<T>(location, value));
    }

    public findRange(from: Vector2, to: Vector2): T[] {
        const result: T[] = [];
        this._findRange(from, to, result);
        return result;
    }

    public find(location: Vector2, error: number): T | null {
        const items = this.findRange(new Vector2(location.x - error, location.y - error),
            new Vector2(location.x + error, location.y + error));
        return items.length > 0 ? items[0] : null;
    }

    public clear() {
        this.entries.splice(0);
    }

    public remove(item: T) {
        for (let i = 0; i < this.entries.length; ++i) {
            if (this.entries[i].value === item) {
                this.entries.splice(i, 1);
                --i;
            }
        }
    }

    public export(nameId: string, toId: (input: T) => number): any[] {
        const result: any[] = [];
        const tmp: any = {};
        for (const entry of this.entries) {
            const id = toId(entry.value);
            if (!(id in tmp)) {
                const count: any = {count: 0};
                count[nameId] = id;
                tmp[id] = count;
                result.push(count);
            }
            ++tmp[id].count;
        }
        return result;
    }

    private _findRange(from: Vector2, to: Vector2, result: T[]) {
        for (const entry of this.entries) {
            if (to.x >= entry.location.x && entry.location.x >= from.x
                && to.y >= entry.location.y && entry.location.y >= from.y && result.indexOf(entry.value) === -1) {
                result.push(entry.value);
            }
        }
    }
}
