
import { Component, Prop, Vue } from 'vue-property-decorator';
import CheckIcon from 'vue-material-design-icons/Check.vue';

@Component({
    components: {CheckIcon},
})
export default class InputField extends Vue {
    public static randomName(): any {
        return 'input_' + Math.random().toString(36).substr(2, 9);
    }

    @Prop({default: null})
    private value!: any;

    @Prop({default: null})
    private name!: any;

    @Prop({default: null})
    private label!: string;

    @Prop({default: false})
    private valid!: boolean;

    @Prop({default: false})
    private invalid!: boolean;

    @Prop({default: false})
    private disabled!: boolean;

    @Prop({default: null})
    private id!: string;

    @Prop({default: 'text'})
    private type!: string;

    @Prop({default: null})
    private helpText!: string|null;

    get idOrRandomString() {
        if (this.id === undefined || !this.id) {
            return InputField.randomName();
        }
        return this.id;
    }
}
