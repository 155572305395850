import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import {RootState} from '@/store';
import api from '@/api';
import {Pagination} from '@/utils/api-tools/pagination';
import {IDampeningFloorCategory} from '@/model/DampeningFloorCategory';
import {IDampeningFloor} from '@/model/DampeningFloor';

export interface DampingFloorState {
    categories: IDampeningFloorCategory[];
    dampingFloors: IDampeningFloor[];
}

@Module
export default class DampingFloorModule extends VuexModule<DampingFloorState, RootState> {
    public categories: IDampeningFloorCategory[] = [];
    public dampingFloors: IDampeningFloor[] = [];

    @Mutation
    public setDampingFloorCategories(value: IDampeningFloorCategory[]) {
        this.categories.splice(0);
        this.categories.push(...value);
    }

    @Mutation
    public setDampingFloors(value: IDampeningFloor[]) {
        this.dampingFloors.splice(0);
        this.dampingFloors.push(...value);
    }

    @MutationAction({
        mutate: ['categories', 'dampingFloors'],
    })
    public async fetchAllDampingFloor() {
        const categories = await api.dampingFloor.getCategories();
        const dampingFloors = await api.dampingFloor.getDampingFloors(new Pagination(0, -1), []);

        return {
            categories: categories.data,
            dampingFloors: dampingFloors.data,
        };
    }
}


