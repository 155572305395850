import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import AuthenticationModule from '@/store-modules/auth';
import PartModule from '@/store-modules/part';
import DampingFloorModule from '@/store-modules/damping-floor';
import PlaygroundModule from '@/store-modules/playground';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {
}

export type RootType = RootState & {
    authentication: AuthenticationModule,
    part: PartModule,
    dampingFloor: DampingFloorModule,
};


export function _createNewStore() {
    return new Vuex.Store({
        state: {},
        mutations: {},
        actions: {},
        getters: {
            findDampingFloor: (state) => (param: any) => {
                const dampingFloorsContent = state.dampingFloor.dampingFloors.content;
                return dampingFloorsContent
                    ? dampingFloorsContent.find((dampingFloor: any) => dampingFloor.id === param)
                    : null
                ;
            },
        },
        modules: {
            authentication: AuthenticationModule,
            part: PartModule,
            dampingFloor: DampingFloorModule,
            playground: PlaygroundModule,
        },
    }) as Store<RootType>;
}

export default _createNewStore();
