
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {IPlayground, PlaygroundStatus, playgroundStatuses} from '@/model/Playground';
    import {url} from '@/utils/http';
    import {PlaygroundApi} from '@/api/playground';
    import CommentTextOutlineIcon from 'vue-material-design-icons/CommentTextOutline.vue';
    import Modal from '@/components/Modal.vue';

    @Component({
    components: {
        CommentTextOutlineIcon,
        Modal,
    },
})
export default class PlaygroundDetailsCard extends Vue {
    @Prop()
    private playground!: IPlayground;
    private showComment = false;

    get status() {
        return playgroundStatuses.get(this.playground.state);
    }

    get isConcept() {
        return this.playground.state === PlaygroundStatus.CONCEPT;
    }

    get isCanceled() {
        return this.playground.state === PlaygroundStatus.CANCELED;
    }

    get isRequested() {
        return this.playground.state !== PlaygroundStatus.CANCELED
            && this.playground.state !== PlaygroundStatus.CONCEPT;
    }

    private loadPlayground(duplicate = false) {
        const playground = this.playground;
        if (duplicate) {
            playground.state = PlaygroundStatus.CONCEPT;
            playground.template = true;
            playground.duplicate = true;
        }
        this.$store.commit('setPlaygroundInfo', playground);
        this.$store.commit('incrementForceReload');
        this.$router.push({name: 'builder'});
    }

    private viewPlaygroundOrder() {
        if (this.playground.id) {
            this.$router.push({
                name: 'playground_order',
                params: { playground_id: this.playground.id.toString() },
            });
        }
    }

    private async deletePlayground() {
        if (this.isConcept && this.playground.id) {
            const playgroundApi = new PlaygroundApi();
            if (confirm('Weet je zeker dat je dit speeltoestel wilt verwijderen? ' +
                'Dit kan niet ongedaan gemaakt worden.')) {
                await playgroundApi.deletePlayground(this.playground.id);
                this.$emit('playgroundDeleted', this.playground.id);

                // destroy the vue listeners, etc
                this.$destroy();
                if (this.$el.parentNode) {
                    // remove the element from the DOM
                    this.$el.parentNode.removeChild(this.$el);
                }
            }
        } else {
            alert('Dit speeltoestel kan niet worden verwijderd.');
        }
    }

    private get imageSrc() {
        if (this.playground.icon) {
            return url(`/static/uploads/${this.playground.icon}`);
        }
        return null;
    }
}
