import {Vector2} from 'three';
import {View3d} from '@/model/3d/view3d';

export class View2d {
    private _scaleViewBoxHeight = '';
    private _scaleViewBoxWidth = '';
    private sizeModifier = 100;
    private magnifiation = 0.5;
    private magnificationBeforePinch = 0.5;
    private height = 14400;
    private width = 21600;
    private _rotation = 0;
    private modifier = 20;
    private _viewBox = '';
    private _x = 0;
    private _y = 0;

    constructor() {
        this.updateViewBox();
    }

    get x(): number {
        return this._x;
    }

    get y(): number {
        return this._y;
    }

    get rotation(): number {
        return this._rotation;
    }

    get scaleViewBoxHeight(): string {
        return this._scaleViewBoxHeight;
    }
    get scaleViewBoxWidth(): string {
        return this._scaleViewBoxWidth;
    }

    get viewBox(): string {
        return this._viewBox;
    }

    public rotate(value: number) {
        this._rotation += value;
    }

    public resetRotation() {
        this._rotation = 0;
    }

    public zoom(value: number) {
        if (this.magnifiation > 0.2 || value > 0) {
            this.magnifiation += (value / 100);
            this.updateViewBox();
        }
    }

    public initPinchZoom() {
        this.magnificationBeforePinch = this.magnifiation;

    }

    public magnify(value: number) {
        const updatedMagnification = this.magnificationBeforePinch * value;
        if (this.magnifiation > 0.2 && updatedMagnification > 0.2) {
            this.magnifiation = updatedMagnification;
            this.updateViewBox();
        }
    }

    public getMagnification(): number {
        return this.magnifiation;
    }

    public resize(width: number, height: number) {
        this.height = height * this.modifier;
        this.width = width * this.modifier;
        this.updateViewBox();
    }

    public moveX(value: number) {
        this._x += value;
        this.updateViewBox();
    }

    public moveY(value: number) {
        this._y += value;
        this.updateViewBox();
    }

    public move(dx: number, dy: number) {
        this._x += dx * this.modifier * this.magnifiation;
        this._y += dy * this.modifier * this.magnifiation;
        this.updateViewBox();
    }

    private updateViewBox() {
        const width = this.width * this.magnifiation;
        const height = this.height * this.magnifiation;
        this._viewBox = '' + (this._x - width / 2) + ' ' + (this._y - height / 2) + ' ' + width + ' ' + height;
        this._scaleViewBoxHeight = '' + 0 + ' ' + 0 + ' ' + 1 + ' ' + height;
        this._scaleViewBoxWidth = '' + 0 + ' ' + 0 + ' ' + width + ' ' + 1;
    }
}
