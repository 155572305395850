
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';

    @Component({
        components: {
            CloseCircleIcon,
        },
    })
    export default class InfoChip extends Vue {
        @Prop({default: null})
        private value!: any;
        @Prop({default: null})
        private top!: string | number | null;
        @Prop({default: null})
        private bottom!: string | number | null;
        @Prop({default: null})
        private left!: string | number | null;
        @Prop({default: null})
        private right!: string | number | null;
        @Prop({default: null})
        private arrowTop!: string | number | null;
        @Prop({default: null})
        private arrowBottom!: string | number | null;
        @Prop({default: false})
        private closeButton!: boolean;
        @Prop({default: false})
        private blue!: boolean;

        private show = true;

        private created() {
            if (this.value !== null) {
                this.show = this.value;
            }
        }

        @Watch('value')
        private valueChanged() {
            this.show = this.value;
        }

        get arrayLeftPos() {
            if (this.arrowTop !== null) {
                return typeof this.arrowTop === 'string' ? this.arrowTop : this.arrowTop + 'px';
            }
            if (this.arrowBottom !== null) {
                return typeof this.arrowBottom === 'string' ? this.arrowBottom : this.arrowBottom + 'px';
            }
            return null;
        }

        get styles() {
            const styles: any = {};
            if (this.top !== null) {
                styles.top = typeof this.top === 'string' ? this.top : this.top + 'px';
            }
            if (this.bottom !== null) {
                styles.bottom = typeof this.bottom === 'string' ? this.bottom : this.bottom + 'px';
            }
            if (this.left !== null) {
                styles.left = typeof this.left === 'string' ? this.left : this.left + 'px';
            }
            if (this.right !== null) {
                styles.right = typeof this.right === 'string' ? this.right : this.left + 'px';
            }
            return styles;
        }

        private close() {
            if (this.value !== null) {
                this.show = false;
                this.$emit('input', false);
            } else {
                this.show = false;
            }
        }
    }
