import {View2d} from '@/model/2d/view2d';
import {Vector2} from 'three';
import {Instance2dData} from '@/model/2d/instance2dData';
import {Utils2d} from '@/model/2d/utils2d';
import {MinimalBoundingBox} from '@/model/2d/minimalBoundingBox';
import {PartCategoryType} from '@/model/PartCategory';

export class World2d {
    private _elements: Instance2dData[] = [];
    private _view: View2d = new View2d();

    constructor(private utils2d: Utils2d) {
    }

    public addItem(instance: Instance2dData, connector?: number, connectedToInstance?: Instance2dData,
                   connectedToConnector?: number, defaultRotation = 0) {
        this.position(instance, connector, connectedToInstance, connectedToConnector, defaultRotation);
        this._elements.push(instance);
    }

    public position(instance: Instance2dData, connector?: number, connectedToInstance?: Instance2dData,
                    connectedToConnector?: number, defaultRotation = 0) {
        let rotation = defaultRotation;
        let x = -instance.width / 2;
        let y = -instance.height / 2;

        if (connectedToInstance && connector !== undefined && connectedToConnector !== undefined) {
            const connectVectorAttach = connectedToInstance.getConnectorPosition(connectedToConnector);
            const connectVectorAttachOther = connectedToInstance.getConnectorPosition(
                (connectedToConnector + 1) % connectedToInstance.getConnectorCount());
            const abused = new Vector2();

            let connectVectorMine = instance.getConnectorPosition((connector + 1) % instance.getConnectorCount());
            const connectVectorMineOther = instance.getConnectorPosition(connector);
            rotation = -this.utils2d.convertAngleTo2d(
                abused.subVectors(connectVectorMine, connectVectorMineOther).angle() -
                abused.subVectors(connectVectorAttach, connectVectorAttachOther).angle());

            connectVectorMine = instance.getConnectorPosition((connector + 1) % instance.getConnectorCount(), rotation);
            x = connectVectorAttach.x - connectVectorMine.x + connectedToInstance.x;
            y = connectVectorAttach.y - connectVectorMine.y + connectedToInstance.y;
        }
        instance.move(x, y, rotation);
    }

    get view(): View2d {
        return this._view;
    }

    get elements(): Instance2dData[] {
        return this._elements;
    }

    public deleteElement(model2d: Instance2dData) {
        const index = this._elements.indexOf(model2d);
        if (index !== -1) {
            this._elements.splice(index, 1);
        }
    }

    public cleanup() {
        this._elements.splice(0);
    }

    public addBoundingBoxMeasurementItems(boundingBox: MinimalBoundingBox) {
        for (const element of this.elements) {
            element.addBoundingBoxMeasurementItems(boundingBox);
        }
    }

    public recalcSafetyAnnotation() {
        for (const element of this._elements) {
            element.recalcSafetyAnnotation(this._elements);
        }
    }

    public addBoundingBoxFallItems(boundingBox: MinimalBoundingBox) {
        for (const element of this.elements) {
            element.addBoundingBoxFallItems(boundingBox);
        }
    }

    public calculatedHeights(): number[] {
        let safetyHeight = 0;
        let playgroundHeight = 0;
        let absoluteHeight = 0;
        for (const element of this._elements) {
            safetyHeight = Math.max(safetyHeight, element.getAbsoluteSafetyHeight());
            playgroundHeight = Math.max(playgroundHeight, element.getAbsolutePartHeight());
            absoluteHeight = Math.max(absoluteHeight, element.getAbsoluteAbsoluteHeight());
        }
        return [playgroundHeight, safetyHeight, absoluteHeight];
    }

    public partNameHighestSafetyHeight(): string {
        let safetyHeight = 0;
        let partName = '';
        for (const element of this._elements) {
            if (element.getAbsoluteSafetyHeight() > safetyHeight) {
                safetyHeight = element.getAbsoluteSafetyHeight();
                partName = element.playgroundPart.part.name;
            }
        }
        return partName;
    }
}
