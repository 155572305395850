
import { Component, Prop, Vue } from 'vue-property-decorator';
import {IPlayground} from '@/model/Playground';
import {url} from '@/utils/http';

@Component
export default class PlaygroundCard extends Vue {
    @Prop()
    private playground!: IPlayground;

    get price() {
        return this.playground.quotationPrice;
    }
    get stairs() {
        return this.playground.numberOfEntrances;
    }
    get slides() {
        return this.playground.numberOfSlides;
    }
    get maxFallHeight() {
        return this.playground.maxHeight ? this.playground.maxHeight * 0.1 : '';
    }

    private loadPlayground() {
        this.$store.commit('setPlaygroundInfo', this.playground);
        this.$store.commit('incrementForceReload');
        this.$router.push({name: 'builder'});
        this.$emit('loadPlayground');
    }

    private get imageSrc() {
        if (this.playground.customIcon) {
            return url(`/static/uploads/${this.playground.customIcon}`);
        } else if (this.playground.icon) {
            return url(`/static/uploads/${this.playground.icon}`);
        }

        return null;
    }
}
