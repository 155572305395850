import {Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import {RootState} from '@/store';
import {IPlayground, IPoleConfig} from '@/model/Playground';
import {Playground} from '@/model/playground/playground';
import {ModificationButton} from '@/model/2d/modificationButton';
import api from '@/api';
import {BalloonManager} from '@/model/BalloonManager';

export interface PlaygroundState {
    playground: any;
    playgroundHasChanges: boolean;
}

@Module
export default class PlaygroundModule extends VuexModule<PlaygroundState, RootState> {
    public playground: any = {};

    public poleConfig: IPoleConfig | null = null;

    public certificationCosts: number | null = null;

    // Watching large objects like playground is troublesome for vue(x)
    // Therefore, use this number-property for watching changes instead.
    public changes = 0;

    public playgroundExport: string | null = localStorage.getItem('playgroundExport');

    public playgroundHasChanges = !!localStorage.getItem('hasChanges');

    public offerPlayground: string  | null = null;

    public offerPlaygroundInfo: any  | null = null;

    public forceReload = 0;

    public initialFloor: number | null = null;

    public initialHeight: number | null = null;

    public playgroundInfo: any | null = null;

    public forceSelectedButton: ModificationButton | null = null;

    public forceSelectedButtonChanged = 0;

    public forceSelectFloor = false;

    public balloonManager: BalloonManager = new BalloonManager();

    @Mutation
    public setForceSelectedButton(value: any) {
        this.forceSelectFloor = false;
        this.forceSelectedButton = value;
        this.forceSelectedButtonChanged++;
    }

    @Mutation
    public setForceSelectFloor(value: any) {
        this.forceSelectFloor = true;
        this.forceSelectedButton = value;
        this.forceSelectedButtonChanged++;
    }

    @Mutation
    public setPlayground(value: any) {
        if (value) {
            this.changes += 1;
        } else {
            this.changes = 0;
        }
        this.playground = value;

        if (this.playground instanceof Playground) {
            const exportedPlayground: IPlayground = this.playground.export();
            const playgroundInfo = this.playgroundInfo;
            if (playgroundInfo) {
                exportedPlayground.name = playgroundInfo.name;
                exportedPlayground.description = playgroundInfo.description;
                exportedPlayground.id = playgroundInfo.template ? undefined : playgroundInfo.id;
                exportedPlayground.template = false;
                exportedPlayground.state = playgroundInfo.state;

                if (exportedPlayground.colorTheme && !exportedPlayground.colorTheme.template) {
                    exportedPlayground.colorTheme.id = undefined;
                }
            }
            this.playgroundExport = JSON.stringify(exportedPlayground);
            this.offerPlayground = this.playgroundExport;
            this.offerPlaygroundInfo = this.playgroundInfo;
            this.initialFloor = null;
            localStorage.setItem('playgroundExport', this.playgroundExport);
        }
    }

    @Mutation
    public clearPlayground() {
        this.playgroundExport = null;
        localStorage.removeItem('playgroundExport');
    }

    @Mutation
    public setPlaygroundInfo(value: any) {
        this.playgroundInfo = value;
    }

    @Mutation
    public resetPlaygroundExportInfo() {
        this.playgroundExport = this.offerPlayground;
        this.playgroundInfo = this.offerPlaygroundInfo;
    }

    @Mutation
    public cancelForceReload() {
        this.forceReload = 0;
    }

    @Mutation
    public incrementForceReload() {
        this.forceReload += 1;
    }

    @Mutation
    public setInitialFloor(value: number) {
        this.initialFloor = value;
    }

    @Mutation
    public setInitialHeight(value: number) {
        this.initialHeight = value;
    }

    @Mutation
    public setPoleConfig(value: IPoleConfig | null) {
        this.poleConfig = value;
    }

    @Mutation
    public setPlaygroundHasChanges(value: boolean) {
        this.playgroundHasChanges = value;
        localStorage.setItem('hasChanges', value ? '1' : '');
    }

    @MutationAction({
        mutate: ['poleConfig'],
    })
    public async fetchPoleConfig() {
        const poleConfig = await api.playground.getPoleConfig();
        return {
            poleConfig : poleConfig.data,
        };
    }

    @Mutation
    public setCertificationCosts(value: number | null) {
        this.certificationCosts = value;
    }

    @MutationAction({
        mutate: ['certificationCosts'],
    })
    public async fetchCertificationCosts() {
        const certificationCosts = await api.playground.getCertificationCostsConfig();
        return {
            certificationCosts : certificationCosts.data,
        };
    }
}
