
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {State} from 'vuex-class';
    import CartIcon from 'vue-material-design-icons/Cart.vue';
    import ContentSaveIcon from 'vue-material-design-icons/ContentSave.vue';
    import AccountBoxIcon from 'vue-material-design-icons/AccountBox.vue';
    import FolderOutlineIcon from 'vue-material-design-icons/FolderOutline.vue';
    import LoginIcon from 'vue-material-design-icons/Login.vue';
    import NewBoxIcon from 'vue-material-design-icons/NewBox.vue';
    import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
    import PlaygroundSaveModal from '@/components/PlaygroundSaveModal.vue';
    import PlaygroundPriceModal from '@/components/PlaygroundPriceModal.vue';
    import {Playground} from '@/model/playground/playground';
    import {IPlayground, IPoleConfig, PlaygroundStatus} from '@/model/Playground';
    import {PlaygroundPriceCalculator} from '@/model/playground/playgroundPriceCalculator';
    import ContactModal from '@/components/ContactModal.vue';
    import {IUser} from '@/model/User';
    import {IPart} from '@/model/Part';
    import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
    import {hasRole} from '@/store-modules/auth';
    import {PlaygroundSaveHandler} from '@/model/playground/playgroundSaveHandler';
    import InfoChip from '@/components/InfoChip.vue';
    import {BalloonManager} from '@/model/BalloonManager';
    import {Dictionary} from 'vue-router/types/router';

    @Component({
        components: {
            InfoChip,
            ContactModal,
            PlaygroundPriceModal,
            PlaygroundSaveModal,
            CartIcon,
            ChevronDownIcon,
            LoginIcon,
            AccountBoxIcon,
            ContentSaveIcon,
            NewBoxIcon,
            FolderOutlineIcon,
            CloseCircleIcon,
        },
    })
    export default class App extends Vue {
        @State((state: any) => state.authentication.authenticated)
        public isAuthenticated!: boolean;
        @State((state: any) => state.playground.playground)
        private playground!: Playground | null;
        @State((state: any) => state.playground.playgroundInfo)
        private playgroundInfo!: IPlayground | null;
        @State((state) => state.part.parts.content)
        private parts!: IPart[];
        @State((state: any) => state.authentication.user)
        private currentUser!: IUser;
        private price = 0;
        private menuOpen = false;
        private playgroundSaveModalOpen = false;
        private playgroundPriceModalOpen = false;
        private contactModalOpen = false;
        private highlightPriceButton = false;
        private showSavedMessage = false;

        @State((state) => state.playground.poleConfig)
        private poleConfig!: IPoleConfig | null;
        @State((state) => state.playground.certificationCosts)
        private certificationCosts!: number;
        private playgroundPriceCalculator: PlaygroundPriceCalculator | null = null;
        @State((state) => state.playground.balloonManager)
        private balloonManager!: BalloonManager;
        @State((state) => state.playground.playgroundExport)
        private playgroundExport!: string | null;

        private async created() {
            await this.$store.dispatch('fetchPoleConfig');
            await this.$store.dispatch('fetchCertificationCosts');
            if (this.poleConfig) {
                this.playgroundPriceCalculator = new PlaygroundPriceCalculator(
                    this.poleConfig,
                    this.parts,
                    this.certificationCosts,
                );
            }
            this.price = this.playgroundPriceCalculator && this.playground instanceof Playground
                ? this.playgroundPriceCalculator.getPrice(this.playground)
                : 0;
        }

        @Watch('parts')
        private async partsUpdated() {
            if (this.playgroundPriceCalculator) {
                this.playgroundPriceCalculator.setParts(this.parts);
            }
        }

        @Watch('poleConfig')
        private async poleConfigUpdated() {
            if (this.playgroundPriceCalculator && this.poleConfig) {
                this.playgroundPriceCalculator.setPoleConfig(this.poleConfig);
            }
        }

        @Watch('$route.name')
        private async routeChanged() {
            this.showSavedMessage = false;
        }

        private loadStoredPlayground() {
            this.$store.commit('setInitialFloor', null);
            let query: Dictionary<string | Array<(string | null)> | null | undefined> = {continue_unsaved: '1'};
            this.$router.push({name: 'builder', query});
        }

        private get isNonStartScreen() {
            return !!this.$route.name && ['login', 'register', 'request_password', 'contact', 'user-edit']
                .indexOf(this.$route.name) >= 0;
        }

        private get isNoUpdateState() {
            let updateStates = [PlaygroundStatus.NEW, PlaygroundStatus.CONCEPT,
                PlaygroundStatus.WAITING_FOR_APPROVAL];
            return this.playgroundInfo && updateStates.indexOf(this.playgroundInfo!.state) === -1
        }

        private get isBuilderScreen() {
            return this.$route.name && ['builder'].indexOf(this.$route.name) >= 0;
        }

        private get isEditablePlayground() {
            let validStates = [PlaygroundStatus.CONCEPT];
            const isAdmin = hasRole(this.currentUser, 'admin');
            if (isAdmin) {
                validStates = [PlaygroundStatus.NEW, PlaygroundStatus.CONCEPT,
                    PlaygroundStatus.CANCELED];
            }
            const notOwnedByMe = !!this.currentUser && !!this.playgroundInfo && !!this.playgroundInfo.user
                && this.playgroundInfo.user.id !== this.currentUser.id;
            return (!this.playgroundInfo || this.playgroundInfo.template
                || validStates.indexOf(this.playgroundInfo.state) !== -1) && (isAdmin || !notOwnedByMe);
        }

        private toggleMenu() {
            this.menuOpen = !this.menuOpen;
            this.showSavedMessage = false;
            this.balloonManager.loginToSave = false;
        }

        private async logout() {
            await this.$store.dispatch('logout');
            this.$router.push('/logout');
        }

        @Watch('$store.state.playground.changes')
        private watchPlayground(numberOfChanges: number) {
            if (this.playground instanceof Playground) {
                this.price = this.playgroundPriceCalculator
                    ? this.playgroundPriceCalculator.getPrice(this.playground)
                    : 0;
            }
        }

        @Watch('balloonManager.price')
        private watchHighlightPrice() {
            if (this.balloonManager.price) {
                this.highlightPriceButton = true;
            }
        }

        private async savePlayground() {
            if (this.playgroundInfo && this.playground && this.playgroundInfo.id) {
                const playgroundSaveHandler = new PlaygroundSaveHandler(this.$api.playground);
                const originalState = this.playgroundInfo.state;
                const playgroundInfo = await playgroundSaveHandler.save(this.playgroundInfo, this.playground);
                playgroundInfo!.state = originalState;
                if (playgroundInfo) {
                    this.$store.commit('setPlaygroundInfo', playgroundInfo);
                }
                this.$store.commit('setPlaygroundHasChanges', false);
                this.showSavedMessage = true;
            } else {
                this.playgroundSaveModalOpen = true;
            }
        }
    }
