import store from '@/store';
import {http, url} from '@/utils/http';
import {IUser} from '@/model/User';
import {IWrapperResponse} from '@/api/generic';

/**
 * Api Accessor for Authentication
 */
export class AuthenticationApi {
    public async login(username: string, password: string) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        const result = await http.post(url('login'), params);
        if (result && result.status === 200) {
            store.commit('setAuthenticated', true);
            store.commit('setUser', result.data.data);
        }
        return result;
    }

    public async logout() {
        const result = store.dispatch('logout');
    }

    public async register(user: IUser) {
        const response = await http.post(url('api/v1/registration/register'), user);
        return response.data as IWrapperResponse<IUser>;
    }

    public async updateAccount(user: IUser) {
        const response = await http.post(url('api/v1/me'), user);
        return response.data as IWrapperResponse<IUser>;
    }

    public async checkEmail(email: string) {
        const response = await http.post(url('api/v1/registration/check_email'), {email});
        return response.data as IWrapperResponse<boolean>;
    }

    public async requestPasswordReset(email: string) {
        const response = await http.post(url('api/v1/registration/request_password'), {email});
        return response.data as IWrapperResponse<boolean>;
    }

    public async resetPassword(token: string, password: string) {
        const response = await http.post(url('api/v1/registration/reset_password'), {token, password});
        return response.data as IWrapperResponse<boolean>;
    }
}
